/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "localization.contentnow.warnerbros.com",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d3nggb6ck181ik.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://3lx6zgg6inhezbtsbzvjec75za.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
