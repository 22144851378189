import { drillDown, indexByKey, sortByKey, assignByKey, unwindByKey, } from "deepdown"

import {
  selectRoleFromLocalizedCredit,
  selectBillingFromOriginalTalent,
  selectCharacterFromOriginalTalent,
  selectRoleFromOriginalTalent,
  selectSeasonOrEpisodeFromTitleMetadata,
} from '../lib/selections'

import TitleTypes from './title-types.json'
import { compile } from './schema-utils'
import schemaXlsxInput from './schema-xlsx-input.json'

import { stringSimilarity } from './similarity'
import { v4 as uuidV4 } from 'uuid'

import {bulk, roles, models} from 'dubcard'

const { RoleTypes } = roles
const { BehaviorMode } = bulk
const { billingSort } = models

const validInput = compile(schemaXlsxInput)

const IpTypesNeedingSeries = [
  TitleTypes.Season,
  TitleTypes.Episode,
  TitleTypes.Pilot,
]

export const equivalentBilling = ({billing, billingLocalized}) => {
  return (billing==="" && billingLocalized===null) || (`${billing}` === `${billingLocalized}`)
}

export const episodeSort = (a, b) => {
  const numA = parseInt(drillDown(a, selectSeasonOrEpisodeFromTitleMetadata), 10)
  const numB = parseInt(drillDown(b, selectSeasonOrEpisodeFromTitleMetadata), 10)

  if (numA < numB) return -1
  if (numA > numB) return 1
  return 0
}

export const getReleaseYear = (title, { country = 'United States' } = {}) => {
  if (title && title.originalReleaseYear) {
    return title.originalReleaseYear
  }
  // const foundCountry = (!title ? [] : title.country || []).find(c => c.name === country) || {}
  // const epochDate = foundCountry.theatricalReleaseDate
  // if (!epochDate) {
  //   return null
  // }
  // return new Date(epochDate).getFullYear()
}

const getSeasonNumber = (mpm, titles) => {
  const episode = mpm && titles && titles[mpm]
  const parentElement = (episode?.parentTitles || []).find(pt => pt.isDefault)
  return parentElement?.parentOriginallyAiredAs
  // const parentTitle = parentElement?.parentMpmNumber && titles[parentElement?.parentMpmNumber]
  // return parentTitle && parentTitle.originallyAiredAs
}

const getSeries = (mpm, titles) => {
  if (!titles) {
    return null
  }

  const title = titles[mpm]
  if (!title) {
    return null
  }

  if (!IpTypesNeedingSeries.includes(title.type)) {
    return null
  }

  const parentTitleElement = (title.parentTitles || []).find(pt => pt.isDefault)
  if (!parentTitleElement) {
    return null
  }

  const parentTitle = titles[parentTitleElement.parentMpmNumber]

  if (parentTitleElement.parentContentType === TitleTypes.Series) {
    return parentTitle
  }

  if (!parentTitle) {
    return null
  }

  if (parentTitle.type !== TitleTypes.Season) {
    return null
  }

  const grandParentElement = (parentTitle.parentTitles || []).find(pt => pt.isDefault)
  if (!grandParentElement) {
    return null
  }

  const grandParentTitle = titles[grandParentElement.parentMpmNumber]
  if (grandParentElement.parentContentType === TitleTypes.Series) {
    return grandParentTitle
  }

  return null
}

const episodicIpTypes = [
  TitleTypes.Series,
  TitleTypes.Season,
  TitleTypes.Episode,
]

const IpTypesNeedingSeason = [
  TitleTypes.Episode,
  TitleTypes.Pilot,
]

export const formatTitle = (mpm, titles, {shortVersion=false, showReleaseYear=true, showIpType=false, defaultTitle= 'Missing Title Data'} = {}) => {
  const title = mpm && titles && titles[mpm]
  const ipTypeIsEpisodic = title && title.type && episodicIpTypes.includes(title.type)
  const ipType = (title && !ipTypeIsEpisodic)
    ? ` - ${title.type}`
    : ''

  const releaseYear = title && title.originalReleaseYear
  const strReleaseYear = (showReleaseYear && releaseYear)
    ? (showIpType)
      ? ` - ${releaseYear}${ipType}`
      : ` - ${releaseYear}`
    : (showIpType)
      ? `${ipType}`
      : ``
  const originalTitle = (title && drillDown(title, 'original.title'.split('.'))) || defaultTitle
  const needsSeason = title && IpTypesNeedingSeason.includes(title.type)
  const needsSeries = title && IpTypesNeedingSeries.includes(title.type)
  const parentSeasonNumber = needsSeason && getSeasonNumber(mpm, titles)
  const parentSeries = needsSeries && getSeries(mpm, titles)
  const parentSeriesTitle = (needsSeries && parentSeries?.original?.title)
    ? `${parentSeries?.original?.title} `
    : ''
  return (title && TitleTypes.Series === title.type)
    ? `Series: ${originalTitle}` + strReleaseYear
    : (title && TitleTypes.Season === title.type)
      ? title.originallyAiredAs
        ? `${parentSeriesTitle}Season ${title.originallyAiredAs}` + strReleaseYear
        : parentSeriesTitle + originalTitle + strReleaseYear
      : (needsSeason && parentSeasonNumber)
        ? (title.originallyAiredAs)
          ? shortVersion
            ? `${parentSeriesTitle}S${parentSeasonNumber}E${title.originallyAiredAs}`
            : `${parentSeriesTitle}S${parentSeasonNumber}E${title.originallyAiredAs}: ${originalTitle}` + strReleaseYear
          : shortVersion
            ? `${parentSeriesTitle}S${parentSeasonNumber}: ${originalTitle}`
            : `${parentSeriesTitle}S${parentSeasonNumber}: ${originalTitle}` + strReleaseYear
        : shortVersion
          ? originalTitle
          : originalTitle + strReleaseYear
}

export const reducerLocalizationsIntoTitles = (accum, loc) => {
  const localized = drillDown(accum, [loc.mpm, 'localized']) || []
  const locIndex = localized.findIndex(ldoc => ldoc.language === loc.language)
  return {
    ...accum,
    [loc.mpm]: {
      ...accum[loc.mpm],
      localized: (locIndex === -1) ? [...localized, loc] : [
        ...localized.slice(0, locIndex), // before
        loc,
        ...localized.slice(locIndex + 1), // after
      ]
    }
  }
}

const reducerAtMpm = ({candidatePoolById, existingAtMpmGroupedById}) => (accum, cand) => {
  const character = drillDown(candidatePoolById, [cand.id, 0])

  if (!existingAtMpmGroupedById[cand.id]) {
    // just append array
    // debugger
    return [...accum, character]
  }

  // overwrite exiting
  const existingIndex = accum.findIndex(exist => exist.id === cand.id)

  // this should not be possible after above check
  if (existingIndex === -1) {
    // but just in case
    // debugger
    return [...accum, character]
  }

  // debugger
  return [
    ...accum.slice(0, existingIndex), // before
    character,
    ...accum.slice(existingIndex + 1), // after
  ]
}

export const reduceCharactersBySeries = (charactersBySeries, characters) => {
  const candidatePool = characters.filter(c => (c.series && c.series.length > 0))
  const candidatePoolById = indexByKey(candidatePool, ['id'])

  const unwoundCharsSeries = unwindByKey(candidatePool, ['series'])
  const candidatesGroupedBySeries = indexByKey(unwoundCharsSeries, ['series'])

  // this assumes characters are unique, no repeats.
  const reducerSeries = (accum, seriesMpm) => {
    const candidates = candidatesGroupedBySeries[seriesMpm]
    const existingAtMpm = charactersBySeries[seriesMpm] || []
    const existingAtMpmGroupedById = indexByKey(existingAtMpm, ['id'])

    const charactersPerSeries = candidates.reduce(reducerAtMpm({candidatePoolById, existingAtMpmGroupedById}), existingAtMpm)
    // debugger

    return {
      ...accum,
      [seriesMpm]: charactersPerSeries,
    }
  }

  const series = Object.keys(candidatesGroupedBySeries)
  return series.reduce(reducerSeries, charactersBySeries)
}

const getEntityAKA = (entitiesById, info) => {
  const entity = entitiesById && entitiesById[info?.id]
  const aka = entity && entity.AKAs.find(aka => aka.id === info?.aka)
  return aka
}

const getProp = (vessel, propPath) => {
  return vessel && drillDown(vessel, propPath)
}

const mapCreditToArray = talents => credit => [
  credit.role,
  getProp(getEntityAKA(talents, credit.talent), ["value"]) || '',
  getProp(credit, ['billingOrder']) || '',
]

const getCreditsForRole = (credits, role, talents) => {
  const filtered = credits.filter(credit => credit.role === role)

  return (filtered.length === 0)
  ? [[role]]
  : filtered.map(mapCreditToArray(talents))
}

const getCreditsWithExclusions = (credits, exclusions, talents) => {
  const filtered = credits.filter(credit => !exclusions.includes(credit.role))

  return filtered.map(mapCreditToArray(talents))
}


export const creditsToArrays = (doc) => {
  if (!validInput(doc)) {
    throw new Error('malformed input: ' + JSON.stringify(validInput.errors))
  }

  const talents = doc?.talents || {}
  const characters = doc?.characters || {}
  const originalCredits = doc?.title?.original?.credits || []
  const originalCharacterCredits = originalCredits
    .filter(credit => credit.character && !!credit.character)
    .sort(billingSort())
  const localizedCredits = doc?.title?.localized?.credits || []
  const localizedCharacterCredits = localizedCredits.filter(credit => credit.character && credit.role === RoleTypes.DUBBING_VOICE)
  const indexLocalizedCharacterCreditsByCharacterId = indexByKey(localizedCharacterCredits, ['character', 'id'])
  const voiceCredits = localizedCredits.filter(credit => !credit.character && credit.role === RoleTypes.DUBBING_VOICE)

  const exclusions = [
    RoleTypes.DUBBING_STUDIO,
    RoleTypes.DUBBING_DIRECTOR,
    RoleTypes.DUBBING_TRANSLATOR,
    RoleTypes.DUBBING_VOICE,
  ]

  return [
    // title metadata
    ["title",     doc?.title?.atom?.title],
    ["released",  doc?.title?.originalReleaseYear],
    ["show type", doc?.title?.type],
    ["mpm",       doc?.title.mpm],
    ["language",  doc?.title?.localized?.language],
    // crew credits
    ["Crew"],
    ["Role", "Talent", "Billing"],
    ...(getCreditsForRole(localizedCredits, RoleTypes.DUBBING_STUDIO, talents)),
    ...(getCreditsForRole(localizedCredits, RoleTypes.DUBBING_DIRECTOR, talents)),
    ...(getCreditsForRole(localizedCredits, RoleTypes.DUBBING_TRANSLATOR, talents)),
    ...(getCreditsWithExclusions(localizedCredits, exclusions, talents)),
    // character credits
    ["Voices - Main"],
    ["Localized Actor", "Localized Character", "Billing", "Original Character"],
    ...(
      originalCharacterCredits.length === 0
      ? [['']]  // make an empty row
      : originalCharacterCredits.map(originalCredit => {
        const characterAKA = getEntityAKA(characters, originalCredit.character)
        const originalCharacter = characterAKA && characterAKA?.original?.value
        const localization = characterAKA && (characterAKA.localizations || []).find(loc => loc.language === doc?.title?.localized?.language)
        const localizedCharacter = localization && localization.value

        const characterId = originalCredit?.character?.id
        const credit = drillDown(indexLocalizedCharacterCreditsByCharacterId, [characterId, 0])
        const localizedActor = credit && getProp(getEntityAKA(talents, credit.talent), ["value"])
        const billingOrder = (credit && credit.billingOrder !== null && credit.billingOrder) || originalCredit.billingOrder

        return [
          localizedActor || '',
          localizedCharacter || '',
          billingOrder || '',
          originalCharacter || '',
        ]
      })
    ),
    // non-character voices
    ["Voices - Other"],
    ...(
      (voiceCredits.length === 0)
      ? [['']] // make an empty row
      : voiceCredits.map(credit => [
        getProp(getEntityAKA(talents, credit.talent), ["value"]) || '',
      ])
    ),
  ]
}

export const formatGraphQlError = (error) => {
  if (error.message) return error.message
  if (error.errors) {
    return error.errors.map(e => e.message || JSON.stringify(e)).join('\n')
  }
  return JSON.stringify(error)
}

// const reducerFilterRolesForCharacters = (accum, title) => {
//   return [
//     ...accum,
//     ...(title.original.credits.filter(r => r.originalCharacterId).map(r => r.originalCharacterId)),
//   ]
// }

// const reducerFilterUnique = (accum, id) => {
//   if (accum[id]) {
//     return accum
//   }
//   return {
//     ...accum,
//     [id]: true,
//   }
// }

export const reducerTitleByIdToTitleByProduct = titlesById => (accum, mpm) => {
  const title = titlesById[mpm]
  if (!title.mpmProductNumber) {
    return accum
  }

  if (!accum[title.mpmProductNumber]) {
    accum[title.mpmProductNumber] = []
  }

  accum[title.mpmProductNumber].push(title)

  return accum
}

export const reducerTitleByIdToTitleByFamily = titlesById => (accum, mpm) => {
  const title = titlesById[mpm]
  if (!title.mpmFamilyNumber) {
    return accum
  }

  if (!accum[title.mpmFamilyNumber]) {
    accum[title.mpmFamilyNumber] = []
  }

  accum[title.mpmFamilyNumber].push(title)

  return accum
}

// export const filterFranchiseCharacters = (mpm, titlesById, titlesByProduct, titlesByFamily) => {
//   const episodeChildMpms = (titlesById[mpm] && titlesById[mpm].mpmProductNumber && titlesByProduct[titlesById[mpm].mpmProductNumber]) || []
//   const seasonParentMpm = titlesById[mpm] && titlesById[mpm].mpmProductNumber
//   const seasonChildMpms = ((titlesById[mpm] && titlesByFamily[mpm]) || []).map(t => t.mpm)
//   const seriesParentMpm = titlesById[mpm] && (titlesById[mpm].mpmFamilyNumber || (seasonParentMpm && titlesById[seasonParentMpm] && titlesById[seasonParentMpm].mpmFamilyNumber))
//   const franchiseTitles = [mpm, ...episodeChildMpms, seasonParentMpm, ...seasonChildMpms, seriesParentMpm].filter(m => m && titlesById[m]).map(m => titlesById[m])
//   return Object.keys(franchiseTitles
//     .reduce(reducerFilterRolesForCharacters, [])
//     .reduce(reducerFilterUnique, {}))
// }

export const livesearchTalentSort = (ra, rb) => {
  if (ra.talentData.creditSequence && !rb.talentData.creditSequence) { return -1 }
  if (!ra.talentData.creditSequence && rb.talentData.creditSequence) { return 1 }
  if (!ra.talentData.creditSequence && !rb.talentData.creditSequence) { return 0 }

  if (ra.talentData.creditSequence < rb.talentData.creditSequence) { return -1 }
  if (ra.talentData.creditSequence > rb.talentData.creditSequence) { return 1 }
  return 0
}

export const reducerFixOneRole = (role) => (accum, key) => {
  // removes `null` values that cause problems in the API
  if (role[key] === undefined || role[key] === null) {
    return accum
  }

  return {
    ...accum,
    [key]: role[key]
  }
}

export const fixRole = (role) => {
  return Object.keys(role).reduce(reducerFixOneRole(role), {})
}

export const mapFixRoles = (role) => {
  return fixRole(role)
}

// TODO: make the front end use the same model as the back end
export const ui2api = ui => {
  return {
    fullName: ui.talent.name,
    roles: ui.roles.map(mapFixRoles),
  }
}

// TODO: make the front end use the same model as the back end
export const api2ui = autofocus => api => ({
  talent: { name: api.fullName, autofocus },
  roles: api.roles,
})

// const makeRole = ({type, originalCharacter, character, isMasked}) => {
//   console.log('---- makeRole - originalCharacter', originalCharacter)
//   return {
//     type,
//     originalCharacter,
//     character,
//     isMasked,
//   }
// }

// const updateRoles = (talent, originalCharacter, field, value) => {
//   const foundRoleIndex = talent.roles.findIndex(r => r.originalCharacter === originalCharacter)
//   return {
//     ...talent,
//     ...(['fullName'].includes(field) ? {[field]: value} : {}),
//     roles: (foundRoleIndex === -1) ? [...talent.roles, makeRole({type: EnumTalentRole.Actor, originalCharacter, [field]: value, })] : [
//       ...talent.roles.slice(0, foundRoleIndex),
//       {
//         ...(talent.roles[foundRoleIndex]),
//         ...(!(['fullName'].includes(field)) ? {[field]: value} : {}),
//       },
//       ...talent.roles.slice(foundRoleIndex + 1),
//     ]
//   }
// }

const roleMatcher = ({ original, localized, talent }) => localizedRoleCandidate => {
  console.log('roleMatcher ', 'original', original, 'localized', localized, 'candidate', localizedRoleCandidate)
  if (original) {
    return localizedRoleCandidate.originalCharacter === original.talentData.character
  } else if (localized) {
    return localizedRoleCandidate.type === drillDown(localized, selectRoleFromLocalizedCredit)
    // return (talent.fullName === localized.fullName)
    //  && (drillDown(talent, selectRoleFromLocalizedCredit) === drillDown(localizedRoleCandidate, selectRoleFromLocalizedCredit))
  }

  // if (original) {
  //   if (original.talentData.role === localizedRoleCandidate.type) {
  //     if (original.talentData.character) {
  //       return original.talentData.character === localizedRoleCandidate.originalCharacter
  //     }
  //     return true
  //   }
  //   return false
  // }
  return false
}


const removeByKey = (o, key) => {
  const keyParent = key.slice(0, -1)
  const parent = drillDown(o, keyParent)
  const keyLeaf = key.slice(-1)[0]
  delete parent[keyLeaf]
}

const clone = x => JSON.parse(JSON.stringify(x))

const makeLocalizedFromOriginal = original => {
  return {
    fullName: '',
    roles: {
      type: drillDown(original, selectRoleFromOriginalTalent),
      originalCharacter: drillDown(original, selectCharacterFromOriginalTalent),
      creditSequence: drillDown(original, selectBillingFromOriginalTalent),
    }
  }
}

const makeAddedLocalizedTalent = ({ original, localized, field, value }) => {
  // console.log('makeAddedLocalizedTalent', original, localized)

  const talentRole = localized ? clone(localized) : makeLocalizedFromOriginal(original)
  if (value === null) {
    removeByKey(talentRole, field)
  } else {
    assignByKey(talentRole, field, value)
  }

  // make a proper talent model
  const talent = {
    fullName: talentRole.fullName || '',
    roles: [
      talentRole.roles || {},
    ],
  }

  return talent
}

const makeUpdatedRole = (talent, roleIndex, localized, { field, value }) => {
  // console.log('makeUpdatedRole', talent, roleIndex, field, value)

  const localizedRole = clone(localized)

  if (value === null) {
    removeByKey(localizedRole, field)
  } else {
    assignByKey(localizedRole, field, value)
  }

  const newTalent = {
    fullName: localizedRole.fullName,
    roles: [
      ...talent.roles.slice(0, roleIndex),
      localizedRole.roles,
      ...talent.roles.slice(roleIndex + 1),
    ]
  }

  return [newTalent]
}

// tries to find a match for an existing localized talent role
// but makes a new localized talent when no match is obvious.
//
// BEHAVIOR:
// find the talent role within the title's localized talent data.
// if the talent's parent is available, then search for the talent's parent, otherwise search for the title's talent,
// because parent documents are used for the 'localized' argument, and they cannot be edited on the child's page.
export const updateLocalizedTalent = (pageData, { lang, field, value, original, localized }) => {
  const mpm = pageData.title.mpm

  const foundLocalizedDocIndex = (pageData.localized || []).findIndex(doc => doc.language === lang)
  // console.log('----- foundLocalizedDocIndex', foundLocalizedDocIndex)

  const foundTalentIndex = (foundLocalizedDocIndex === -1)
    ? -1
    : !localized ? -1 : (pageData.localized[foundLocalizedDocIndex].talent || []).findIndex(talent => {
      return (talent.fullName === localized.fullName)
    })

  const foundRoleIndex = (foundTalentIndex === -1)
    ? -1
    : (pageData.localized[foundLocalizedDocIndex].talent[foundTalentIndex].roles.findIndex(roleMatcher({ original, localized, talent: pageData.localized[foundLocalizedDocIndex].talent[foundTalentIndex] })))

  const localizedDoc = (foundLocalizedDocIndex > -1)
    ? {
      ...(pageData.localized[foundLocalizedDocIndex]),
      talent: (foundTalentIndex === -1)
        ? [
          makeAddedLocalizedTalent({ original, localized, field, value }),
          ...(pageData.localized[foundLocalizedDocIndex].talent)
        ]
        : [
          ...(pageData.localized[foundLocalizedDocIndex].talent.slice(0, foundTalentIndex)),
          ...(makeUpdatedRole(pageData.localized[foundLocalizedDocIndex].talent[foundTalentIndex], foundRoleIndex, localized, { field, value })),
          ...(pageData.localized[foundLocalizedDocIndex].talent.slice(foundTalentIndex + 1)),
        ]
    }
    : {
      mpm,
      language: lang,
      talent: [makeAddedLocalizedTalent({ original, localized, field, value })]
    }

  const localizedSet = (foundLocalizedDocIndex > -1)
    ? [
      ...(pageData.localized.slice(0, foundLocalizedDocIndex)),
      localizedDoc,
      ...(pageData.localized.slice(foundLocalizedDocIndex + 1)),
    ]
    : [
      ...(pageData.localized),
      localizedDoc,
    ]

  return {
    title: pageData.title,
    localized: localizedSet,
    episodes: pageData.episodes,
    seasons: pageData.seasons,
    series: pageData.series,
  }
}

export const findLocalizedByOriginalCharacter = (pageData, lang, ocName) => {
  const foundLocalizedDocIndex = (pageData.localized || []).findIndex(doc => doc.language === lang)

  const foundTalentIndex = (foundLocalizedDocIndex === -1)
    ? -1
    : (pageData.localized[foundLocalizedDocIndex].talent || []).findIndex(talent => {
      const foundRoleIndex = (talent.roles || []).findIndex(lr => lr.originalCharacter === ocName)
      return foundRoleIndex > -1
    })

  return drillDown(pageData, ['localized', foundLocalizedDocIndex, 'talent', foundTalentIndex])
}

const makeTalent = (addedRole) => {
  console.log('---- makeTalent, ', addedRole)
  const { fullName, roles } = addedRole
  return {
    fullName,
    roles: [
      roles,
    ]
  }
}

const addRoleByName = (existingTalent, addedRole) => {
  console.log('---- addRoleByName, ', existingTalent, addedRole)
  return {
    ...existingTalent,
    roles: [
      ...existingTalent.roles,
      addedRole.roles,
    ]
  }
}

export const addLocalizedTalent = (pageData, addedRole, { lang, locale }) => {
  const mpm = pageData.title.mpmNumber
  const foundLocalizedDocIndex = (pageData.localized || []).findIndex(doc => doc.language === lang)

  // check if we already have a document for the same human...by looking for a match on names.
  const foundTalentIndex = (foundLocalizedDocIndex === -1)
    ? -1
    : pageData.localized[foundLocalizedDocIndex].talent.findIndex(t => (t.fullName || '').toLowerCase(locale) === (addedRole.fullName || '').toLowerCase(locale))

  const localizedDoc = (foundLocalizedDocIndex > -1)
    ? {
      ...(pageData.localized[foundLocalizedDocIndex]),
      talent: (foundTalentIndex === -1)
        ? [
          makeTalent(addedRole),
          ...(pageData.localized[foundLocalizedDocIndex].talent)
        ]
        : [
          ...(pageData.localized[foundLocalizedDocIndex].talent.slice(0, foundTalentIndex)),
          addRoleByName(pageData.localized[foundLocalizedDocIndex].talent[foundTalentIndex], addedRole),
          ...(pageData.localized[foundLocalizedDocIndex].talent.slice(foundTalentIndex + 1)),
        ]
    }
    : {
      mpm,
      language: lang,
      talent: [makeTalent(addedRole)]
    }

  const localizedSet = (foundLocalizedDocIndex > -1)
    ? [
      ...(pageData.localized.slice(0, foundLocalizedDocIndex)),
      localizedDoc,
      ...(pageData.localized.slice(foundLocalizedDocIndex + 1)),
    ]
    : [
      ...(pageData.localized),
      localizedDoc,
    ]

  return {
    ...pageData,
    localized: localizedSet,
  }
}

export const removeLocalizedRole = (pageData, { lang, localized }) => {
  const langIndex = pageData.localized.findIndex(ldoc => ldoc.language === lang)

  if (langIndex === -1) {
    return { ...pageData }
  }

  const talentIndex = pageData.localized[langIndex].talent.findIndex(t => {
    // TODO: may need to examine t.roles if `t.fullName` is not available.
    return (t.fullName === localized.fullName)
  })

  if (talentIndex === -1) {
    return { ...pageData }
  }

  const talent = pageData.localized[langIndex].talent[talentIndex]
  // TODO: need better solution
  const roleIndex = talent.roles.findIndex(r => {
    // console.log('role', r, localized)
    return false
  })

  // use [] array to wrap single element OR null
  const talentResult = ((roleIndex !== -1) && (talent.roles.length > 1))
    ? [{
      ...talent,
      roles: []
    }]
    : []

  const ldoc = {
    ...(pageData.localized[langIndex]),
    talent: [
      ...(pageData.localized[langIndex].talent.slice(0, talentIndex)),
      ...talentResult,
      ...(pageData.localized[langIndex].talent.slice(talentIndex + 1)),
    ]
  }
  const localizedSet = [
    ...(pageData.localized.slice(0, langIndex)),
    ldoc,
    ...(pageData.localized.slice(langIndex + 1)),
  ]
  return {
    ...pageData,
    localized: localizedSet,
  }
}


const reduceArray = b => (accum, elemA, elemIndex) => {
  return accum || drifted(elemA, b[elemIndex])
}

const reduceObject = (a, b) => (accum, keyA) => {
  return accum || drifted(a[keyA], b[keyA])
}

export const drifted = (a, b) => {
  // console.log('drifted a,b', a, b)
  const typeA = typeof a
  if (typeA !== typeof b) {
    // console.log('--- typeof')
    return true
  }

  if (Array.isArray(a) && Array.isArray(b)
    && a.length !== b.length
    && a.reduce(reduceArray(b), false)) {
    console.log('--- isArray')
    return true
  } else if (typeA === 'object') {
    if (a === null) {
      // console.log('---', typeA, 'a === null', b)
      return b !== null
    }

    // check keys
    if (Object.keys(a).length !== Object.keys(b).length) {
      // console.log('---', typeA, (Object.keys(a).length !== Object.keys(b).length))
      return true
    }
    // console.log('---', typeA, (Object.keys(a).length !== Object.keys(b).length))
    return Object.keys(a).reduce(reduceObject(a, b), false)
  }

  // console.log('--- scalar', typeA, (Object.keys(a).length !== Object.keys(b).length))
  return a !== b
}

export const getSortedTalent = ({talents, inputValue}) => {
  const flatTalent = Object.keys(talents).map(tId => talents[tId])
  const sortedTalent = flatTalent.sort(sortByKey({ key: ['updatedOn'], order: true }))
  return sortedTalent
}

const chooseBehavior = (atomCharacter, candidates, threshold) => {
  const billingOrder = atomCharacter.billingOrder

  if (candidates.length < 1) {
    return {
      mode: BehaviorMode.CREATE_NEW,
      candidates,
      ...(billingOrder ? {billingOrder} : {}),
    }
  }

  // assumes the candidates are pre-sorted by score
  if (candidates[0].score > threshold) {
    return {
      mode: BehaviorMode.USE_EXISTING,
      candidates,
      character: drillDown(candidates, '0.character'.split('.')),
      ...(billingOrder ? {billingOrder} : {}),
    }
  }

  return {
    mode: BehaviorMode.CREATE_NEW,
    candidates,
    ...(billingOrder ? {billingOrder} : {}),
  }
}

export const reducerComputeBehavior = ({matchCandidates, minScore}) => (accum, atomCharacter) => {
  const candidates = matchCandidates[atomCharacter.characterName]
  const behavior = chooseBehavior(atomCharacter, candidates, minScore)

  return { ...accum, [atomCharacter.characterName]: behavior }
}

const matchSort = (a, b) => {
  return b.score - a.score
}

export const reducerRankMatchCandidates = unwoundAkas => (accum, atomCharacter) => {
  const candidates = unwoundAkas.map(character => {
    const score = stringSimilarity(atomCharacter.characterName, character.AKAs.original.value)

    return {
      atomCharacter,
      character,
      score,
    }
  }).sort(matchSort)

  return {
    ...accum,
    [atomCharacter.characterName]: candidates,
  }
}

export const reducerImportedAtomCharactersToApiBehaviors = atomImportData => (accum, characterName) => {
  const {mode, character, ignore, billingOrder} = atomImportData[characterName]

  if (ignore) {
    return accum
  }

  return [
    ...accum,
    {
      characterName,
      mode,
      ...((mode !== BehaviorMode.USE_EXISTING) ? {} : {
        character: {
          id: character.id,
          aka: character.AKAs.id,
        },
      }),
      ...(billingOrder ? {billingOrder} : {}),
    },
  ]
}

export const reducerRankFranchiseCharacters = ({
  xlsxRolesByCharacterName,
  unwoundFranchiseCharacterAKAs,
}) => (accum, xlsxCharacterName) => {

  const xlsxRole = xlsxRolesByCharacterName[xlsxCharacterName][0]

  // M x 1
  const scores = unwoundFranchiseCharacterAKAs.map(franchiseCharacter => {
    const score = stringSimilarity(franchiseCharacter.AKAs.original.value, xlsxCharacterName)
    return {
      score,
      franchiseCharacter,
    }
  }).sort(matchSort)

  return {
    ...accum,
    [xlsxCharacterName]: {
      xlsxRole,
      scores,
    },
  }
}

export const reducerRankTalentSearches = ({
  xlsxRolesByTalentName,
  talentSearchesByQuery,
}) => (accum, xlsxTalentName) => {
  const xlsxRole = xlsxRolesByTalentName[xlsxTalentName][0]

  const talentSearchResults = drillDown(talentSearchesByQuery, [xlsxTalentName, 0, 'result']) || []

  const unwoundSearchResultAkas = unwindByKey(talentSearchResults, ['AKAs'])

  // M x 1
  const scores = unwoundSearchResultAkas.map(talent => {
    const score = stringSimilarity(talent.AKAs.value, xlsxTalentName)
    return {
      score,
      talent,
    }
  }).sort(matchSort)

  return {
    ...accum,
    [xlsxTalentName]: {
      xlsxRole,
      scores,
    },
  }
}

const mergeXlsxImportState = (accum, updates) => ({
  ...accum,
  credits: [
    ...accum.credits,
    ...updates.credits,
  ],
  charactersByName: {
    ...accum.charactersByName,
    ...updates.charactersByName,
  },
  talentsByName: {
    ...accum.talentsByName,
    ...updates.talentsByName,
  },
})


export const reducerXlsxToImportBehavior = ({
  // xlsxRolesByCharacterName,
  // xlsxRolesByTalentName,
  matchScoresFranchiseCharacterByXlsxCharacterName,
  matchScoresTalentSearchByXlsxTalentName,
  minScoreCharacter,
  minScoreTalent,
}) => (accum, xlsxRole) => {
  // console.log('ImportReview/useEffect, role:', xlsxRole)

  const isCharacter = (xlsxRole.isCharacter)
  // const isVoice = (xlsxRole.type === RoleTypes.DUBBING_VOICE && !xlsxRole.isCharacter)
  // const isCrew = !isCharacter && !isVoice

  const credit = {role: xlsxRole.type, xlsxRole, ...(!xlsxRole.billing ? {} : {billingOrder: xlsxRole.billing})}
  const updates = {credits: [credit], charactersByName: {}, talentsByName: {}}

  if (isCharacter) {
    if (xlsxRole.originalCharacterName) {
      if (!accum.charactersByName[xlsxRole.originalCharacterName]) {
        // check if we have a franchise character for that name already
        const franchise = matchScoresFranchiseCharacterByXlsxCharacterName[xlsxRole.originalCharacterName]
        if (franchise.scores && franchise.scores.length > 0) {
          const best = franchise.scores[0]
          if (best.score >= minScoreCharacter) {
            const entity = {
              id: best.franchiseCharacter.id,
              aka: best.franchiseCharacter.AKAs.id,
            }
            updates.charactersByName[xlsxRole.originalCharacterName] = entity
            credit.character = {
              mode: BehaviorMode.USE_EXISTING,
              entity,
            }
          } else {
            const entity = {
              id: uuidV4(),
              aka: uuidV4(),
            }
            updates.charactersByName[xlsxRole.originalCharacterName] = entity
            credit.character = {
              mode: BehaviorMode.CREATE_NEW,
              entity,
            }
          }
        } else {
          const entity = {
            id: uuidV4(),
            aka: uuidV4(),
          }
          updates.charactersByName[xlsxRole.originalCharacterName] = entity
          credit.character = {
            mode: BehaviorMode.CREATE_NEW,
            entity,
          }
        }
      } else {
        const entity = accum.charactersByName[xlsxRole.originalCharacterName]
        credit.character = {
          mode: BehaviorMode.REF_IMPORTED,
          entity,
        }
      }
    }
  }

  if (!accum.talentsByName[xlsxRole.talentName]) {
    const searchResultScores = matchScoresTalentSearchByXlsxTalentName[xlsxRole.talentName].scores || []
    if (searchResultScores.length > 0) {
      const best = searchResultScores[0]
      if (best.score >= minScoreTalent) {
        const entity = {
          id: best.talent.id,
          aka: best.talent.AKAs.id,
        }
        updates.talentsByName[xlsxRole.talentName] = entity

        credit.talent = {
          mode: BehaviorMode.USE_EXISTING,
          entity,
        }
      } else {
        const entity = {
          id: uuidV4(),
          aka: uuidV4(),
        }
        updates.talentsByName[xlsxRole.talentName] = entity

        credit.talent = {
          mode: BehaviorMode.CREATE_NEW,
          entity,
        }
      }
    } else {
      const entity = {
        id: uuidV4(),
        aka: uuidV4(),
      }
      updates.talentsByName[xlsxRole.talentName] = entity
      credit.talent = {
        mode: BehaviorMode.CREATE_NEW,
        entity,
      }
    }
  } else {
    const entity = accum.talentsByName[xlsxRole.talentName]
    credit.talent = {
      mode: BehaviorMode.USE_EXISTING,
      entity,
    }
  }

  // if (credit.character) {
  //   console.log('reducerXlsxToImportBehavior: character name', credit.xlsxRole.originalCharacterName, credit.character.mode)
  // }
  // console.log('reducerXlsxToImportBehavior: talent name', credit.xlsxRole.talentName, credit.talent.mode)
  return mergeXlsxImportState(accum, updates)
}

export const computeImportXlsxBehaviors = async ({
  unwoundFranchiseCharacterAKAs, // length M
  importedXlsx,
  minScoreCharacter,
  minScoreTalent,
  searchTalent,
}) => {

  // length N
  const xlsxRoles = (importedXlsx && importedXlsx.data && importedXlsx.data.roles) || []
  const xlsxRolesByCharacterName = indexByKey(xlsxRoles.filter(r => (r.isCharacter && r.originalCharacterName)), ['originalCharacterName'])
  const xlsxRolesByTalentName = indexByKey(xlsxRoles.filter(r => r.talentName), ['talentName'])

  // complexity: N x M
  const matchScoresFranchiseCharacterByXlsxCharacterName = Object.keys(xlsxRolesByCharacterName)
    .reduce(reducerRankFranchiseCharacters({
      xlsxRolesByCharacterName,
      unwoundFranchiseCharacterAKAs,
      minScore: minScoreCharacter,
    }), {})

  const talentSearches = Object.keys(xlsxRolesByTalentName)
    .map(xlsxTalentName => searchTalent({akaValue: xlsxTalentName})
      .then(result => ({
        result,
        query: xlsxTalentName,
      })))

  // search in parallel because each lambda will search against mongo atlas,
  // and atlas should be able to handle the concurrency.
  return Promise.all(talentSearches).then(talentSearchResults => {

    // length T[key]
    const talentSearchesByQuery = indexByKey(talentSearchResults, ['query'])

    // complexity: N x T[key]
    const matchScoresTalentSearchByXlsxTalentName = Object.keys(xlsxRolesByTalentName)
      .reduce(reducerRankTalentSearches({
        xlsxRolesByTalentName,
        talentSearchesByQuery,
      }), {})

    // complexity: N x 1
    const initBehaviors = {credits: [], charactersByName: {}, talentsByName: {}, matchScoresTalentSearchByXlsxTalentName}
    const behaviors = xlsxRoles.reduce(reducerXlsxToImportBehavior({
      matchScoresTalentSearchByXlsxTalentName,
      matchScoresFranchiseCharacterByXlsxCharacterName,
      minScoreCharacter,
      minScoreTalent,
    }), initBehaviors)

    return behaviors
  })
}

export const reducerXlsxCreditToImportApi = (accum, creditInfo) => {
  if (creditInfo.ignore) {
    return accum
  }

  const credit = {
    role: creditInfo.role,
    talent: creditInfo.talent,
    ...(creditInfo?.character ? {character: creditInfo.character} : {}),
    ...(creditInfo?.billingOrder ? {billingOrder: creditInfo.billingOrder} : {}),
  }

  const characters = []

  if (creditInfo?.character?.mode === BehaviorMode.CREATE_NEW) {
    characters.push({
      name: creditInfo.xlsxRole.originalCharacterName,
      entity: creditInfo.character.entity,
    })
  }

  const talents = []

  if (creditInfo?.talent?.mode === BehaviorMode.CREATE_NEW) {
    talents.push({
      name: creditInfo.xlsxRole.talentName,
      entity: creditInfo.talent.entity,
    })
  }

  return {
    ...accum,
    ...((characters.length === 0)
      ? {characters: accum.characters}
      : {characters: [...accum.characters, ...characters]}
    ),
    ...((talents.length === 0)
      ? {talents: accum.talents}
      : {talents: [...accum.talents, ...talents]}
    ),
    credits: [
      ...accum.credits,
      credit,
    ],
  }
}
