// import {
//   useCallback,
//   useState,
// } from "react"

import {
  // Button,
  // TextField,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  TalentAkaInputBar: {
    padding: theme.spacing(1),
  },
}))

// const isValid = (akaValue) => { //, langValue) => {
//   return (akaValue && akaValue.length > 1) // && (langValue && langValue.length > 1)
// }

const TalentAkaInputBar = (
// {
//   addAkasToTalent,
//   selected,
// }
) => {
  const classes = useStyles()
  // const [akaValue, setAkaValue] = useState('')
  // TODO: require language for talent aka
  // const [langValue, setLangValue] = useState('')
  // const valid = isValid(akaValue) //, langValue)

  // const onChangeAkaValue = useCallback(e => {
  //   setAkaValue(e.target.value)
  // }, [setAkaValue])

  // const onClickAddAka = useCallback(() => {
  //   if (!selected) {
  //     return
  //   }
  //   const input = {
  //     id: selected.id,
  //     AKAs: [{
  //       value: akaValue,
  //       // language: langValue,
  //     }]
  //   }
  //   addAkasToTalent(input).then(result => {
  //     console.log('/talent/onClickAddAka, result', result)
  //     setAkaValue('')
  //   }).catch(error => {
  //     console.error('/talent/onClickAddAka, error', error)
  //   })
  // }, [selected, akaValue, addAkasToTalent, setAkaValue])

  return (
<Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.TalentAkaInputBar}>
  <Typography variant="h4">AKAs</Typography>
  {/* <Filler /> */}
  <Grid item xs={2} md={4} lg={6}></Grid>
  {/* <TextField
    fullWidth
    value={akaValue || ''}
    onChange={onChangeAkaValue}
  />
  <Button
    disabled={!valid}
    onClick={onClickAddAka}
    color="primary"
  >ADD</Button> */}
</Grid>
  )
}

export default TalentAkaInputBar
