import { Grid, Typography, makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchResultItem from './SearchResultItem'

const useStyles = makeStyles(theme => ({
  ResultsHeader: {
    textAlign: 'center',
  },
  heading: {
    whiteSpace: 'nowrap',
  },
  resultsContainer: {
    height: '100%',
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  resultsListContainer: {
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
}))

const NoResults = ({
  term,
}) => {
  return (
<div>
  <Typography variant="h4">No results for</Typography>
  <Typography variant="h2">{term}</Typography>
</div>
  )
}

const SearchProgress = ({term, label}) => {
  return (
    <Grid container direction="column" wrap="nowrap" justify="flex-start" alignItems="center">
      <Typography variant="h4">{label}</Typography>
      <Typography variant="h2">{term}</Typography>
      <CircularProgress color="secondary" />
    </Grid>
  )
}

const ResultsHeader = ({
  data,
  heading,
  label,
}) => {
  const classes = useStyles()
  const { fetching, results=[], term, /*summary*/ } = data

  return (
  <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap" className={classes.ResultsHeader}>
    {(fetching)
      ? <SearchProgress {...{term, label}} />
      : (term && ((!results) || (results && results.length === 0)))
        ? <NoResults term={term} />
        : <Typography variant="h4" align="center" className={classes.heading}>{heading}</Typography>
    }
  </Grid>
  )
}

const SearchResults = ({ heading="Search Results", label="Searching for", data, onClickClearTerm, titles, }) => {
  const classes = useStyles()
  const {results, fetching} = data

  return (
<Grid container direction="column" wrap="nowrap" alignItems="center" className={classes.resultsContainer}>
  <ResultsHeader {...{data, heading, label}} />
  {(!fetching) && (
  <div className={classes.resultsListContainer}>
    {(results || []).map(r => <SearchResultItem
      key={`/search/results/${r.mpm}`}
      result={r}
      titles={titles}
      onClickPoster={onClickClearTerm}
      onClickLang={onClickClearTerm}
      />)}
  </div>
  )}
</Grid>
  )
}

export default SearchResults
