import { API } from 'aws-amplify'
import * as Query from '../graphql/queries'
import * as Mutation from '../graphql/mutations'
import { drillDown } from 'deepdown'
import preferredLanguages from '../lib/languages-preferred.json'
import {formats} from 'dubcard'
const {FormatList} = formats

// title
export const addCreditsToLocalizedTitle = async (input) => {
  return API.graphql({
    query: Mutation.addCreditsToLocalizedTitle,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'addCreditsToLocalizedTitle']))
}

export const removeCreditFromLocalizedTitle = async (input) => {
  return API.graphql({
    query: Mutation.removeCreditFromLocalizedTitle,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'removeCreditFromLocalizedTitle']))
}

export const updateTalentForCharacterCredit = async (input) => {
  return API.graphql({
    query: Mutation.updateTalentForCharacterCredit,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'updateTalentForCharacterCredit']))
}

export const updateBillingForOriginalCharacterCredit = async (input) => {
  return API.graphql({
    query: Mutation.updateBillingForOriginalCharacterCredit,
    variables: {input}
  }).then(res => drillDown(res, ['data', 'updateBillingForOriginalCharacterCredit']))
}

export const updateBillingForLocalizedCredit = async (input) => {
  return API.graphql({
    query: Mutation.updateBillingForLocalizedCredit,
    variables: {input}
  }).then(res => drillDown(res, ['data', 'updateBillingForLocalizedCredit']))
}

export const updateEpisodeOverrideForSeason = async (input) => {
  return API.graphql({
    query: Mutation.updateEpisodeOverrideForSeason,
    variables: {input}
  }).then(res => drillDown(res, ['data', 'updateEpisodeOverrideForSeason']))
}

export const removeEpisodeOverrideForSeason = async (input) => {
  return API.graphql({
    query: Mutation.removeEpisodeOverrideForSeason,
    variables: {input}
  }).then(res => drillDown(res, ['data', 'removeEpisodeOverrideForSeason']))
}

export const searchTitles = async (input) => {
  return API.graphql({
    query: Query.searchTitles,
    variables: { input },
  }).then(res => drillDown(res, ['data', 'searchTitles']))
}

export const recentUpdates = async () => {
  return API.graphql({
    query: Query.getRecentTitleUpdates,
    variables: {}
  }).then(res => drillDown(res, ['data', 'getRecentTitleUpdates']))
}

export const listDeliverySettings = async (input) => {
  return API.graphql({
    query: Query.listDeliverySettings,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'listDeliverySettings']))
}

export const getTitleMetadata = async (input) => {
  return API.graphql({
    query: Query.getTitleMetadata,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'getTitleMetadata']))
}

export const getCreditsForLocalizedTitle = async (input) => {
  return API.graphql({
    query: Query.getCreditsForLocalizedTitle,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'getCreditsForLocalizedTitle']))
}

// export const updateLocalizedTitle = async (input) => {
//   return API.graphql({
//     query: Mutation.updateLocalizedTitle,
//     variables: {input},
//   }).then(res => drillDown(res, ['data', 'updateLocalizedTitle']))
// }

// talent
export const createTalent = async (input) => {
  return API.graphql({
    query: Mutation.createTalent,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'createTalent']))
}

export const addAkasToTalent = async (input) => {
  return API.graphql({
    query: Mutation.addAkasToTalent,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'addAkasToTalent']))
}

export const updateTalentAka = async (input) => {
  return API.graphql({
    query: Mutation.updateTalentAka,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'updateTalentAka']))
}

export const searchTalent = async (input) => {
  return API.graphql({
    query: Query.searchTalent,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'searchTalent']))
}

// character
export const importCharactersFromAtom = async (input) => {
  return API.graphql({
    query: Mutation.importCharactersFromAtom,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'importCharactersFromAtom']))
}

export const createCharacter = async (input) => {
  return API.graphql({
    query: Mutation.createCharacter,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'createCharacter']))
}

export const addAkasToCharacter = async (input) => {
  return API.graphql({
    query: Mutation.addAkasToCharacter,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'addAkasToCharacter']))
}

export const addTitlesToCharacterAka = async (input) => {
  return API.graphql({
    query: Mutation.addTitlesToCharacterAka,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'addTitlesToCharacterAka']))
}

export const removeTitlesFromCharacterAka = async (input) => {
  return API.graphql({
    query: Mutation.removeTitlesFromCharacterAka,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'removeTitlesFromCharacterAka']))
}

export const updateCharacterAkaOriginal = async (input) => {
  return API.graphql({
    query: Mutation.updateCharacterAkaOriginal,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'updateCharacterAkaOriginal']))
}

export const updateLocalizationToCharacterAka = async (input) => {
  return API.graphql({
    query: Mutation.updateLocalizationToCharacterAka,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'updateLocalizationToCharacterAka']))
}

export const getAtomCharacters = async (input) => {
  return API.graphql({
    query: Query.getAtomCharacters,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'getAtomCharacters']))
}

// export const deleteCharacter = async (input) => {
//   return API.graphql({
//     query: Mutation.deleteCharacter,
//     variables: {input},
//   }).then(res => drillDown(res, ['data', 'deleteCharacter']))
// }

// export const searchCharacter = async (input) => {
//   return API.graphql({
//     query: Query.searchCharacter,
//     variables: {input},
//   }).then(res => drillDown(res, ['data', 'searchCharacter']))
// }

export const fetchFormats = async () => {
  return {
    data: FormatList,
  }
}

export const getLanguageCodes = async () => {
  // TODO: call language service instead of hard-coding
  return Promise.resolve({
    data: {
      getLanguageCodes: preferredLanguages
    }
  }).then(res => drillDown(res, ['data', 'getLanguageCodes']))
}

// export const getTitlesForTalent = async (input) => {
//   return API.graphql({
//     query: Query.searchCharacter,
//     variables: {input},
//   }).then(res => drillDown(res, ['data', 'getTitlesForTalent']))
// }

export const findCharactersByFranchiseId = async (input) => {
  return API.graphql({
    query: Query.findCharactersByFranchiseId,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'findCharactersByFranchiseId']))
}

export const searchCharacter = async (input) => {
  return API.graphql({
    query: Query.searchCharacter,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'searchCharacter']))
}

// rendering

export const renderRasterBySvg = async (input) => {
  return API.graphql({
    query: Mutation.renderRasterBySvg,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'renderRasterBySvg']))
}

export const createDelivery = async (input) => {
  return API.graphql({
    query: Mutation.createDelivery,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'createDelivery']))
}

export const getDeliveries = async (input) => {
  return API.graphql({
    query: Query.getDeliveries,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'getDeliveries']))
}

export const getUserAttributes = async (input) => {
  return API.graphql({
    query: Query.getUserAttributes,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'getUserAttributes']))
}

export const importCreditsFromXlsx = async (input) => {
  return API.graphql({
    query: Mutation.importCreditsFromXlsx,
    variables: {input},
  }).then(res => drillDown(res, ['data', 'importCreditsFromXlsx']))
}
