// import { useCallback, useState } from "react"
// import { drillDown } from "deepdown"
import {
  // Button,
  // TextField,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"
// import Filler from '../Filler'

const useStyles = makeStyles(theme => ({
  CharacterAkaInputBar: {
    padding: theme.spacing(1),
  },
}))

// const isValid = (akaValue) => { //, langValue) => {
//   return (akaValue && akaValue.length > 1) // && (langValue && langValue.length > 1)
// }

const CharacterAkaInputBar = ({
  addAkasToCharacter,
  selected,
}) => {
  const classes = useStyles()
  // const [akaValue, setAkaValue] = useState('')
  // TODO: require language for talent aka
  // const [langValue, setLangValue] = useState('')
  // const valid = isValid(akaValue) //, langValue)

  // const onChangeAkaValue = useCallback(e => {
  //   setAkaValue(e.target.value)
  // }, [setAkaValue])

  // const onClickAddAka = useCallback(() => {
  //   if (!selected) {
  //     return
  //   }
  //   debugger
  //   const langValue = (selected && drillDown(selected, 'AKAs.0.original.language'.split('.'))) || 'en'
  //   const input = {
  //     id: selected.id,
  //     AKAs: [{
  //       // id: uuidV4(),
  //       mpm: [],
  //       original: {
  //         value: akaValue,
  //         language: langValue,
  //       }
  //     }]
  //   }
  //   addAkasToCharacter(input).then(result => {
  //     console.log('/character/onClickAddAka, result', result)
  //     setAkaValue('')
  //   }).catch(error => {
  //     console.error('/character/onClickAddAka, error', error)
  //   })
  // }, [selected, akaValue, addAkasToCharacter, setAkaValue])

  return (
<Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.CharacterAkaInputBar}>
  <Typography variant="h4">AKAs</Typography>
  {/* <Filler /> */}
  <Grid item xs={2} md={4} lg={6}></Grid>
  {/* <TextField
    fullWidth
    value={akaValue || ''}
    onChange={onChangeAkaValue}
  />
  <Button
    disabled={!valid}
    onClick={onClickAddAka}
    color="primary"
  >ADD</Button> */}
</Grid>
  )
}

export default CharacterAkaInputBar
