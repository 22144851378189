import React, { Fragment } from 'react'

import {Link as RouterLink} from 'react-router-dom'

import { drillDown, } from 'deepdown'
import { Button, Grid, TextField, Typography, makeStyles, IconButton, Paper, } from '@material-ui/core'
import { Edit as EditIcon, Link as LinkIcon  } from '@material-ui/icons'

import clsx from 'clsx'

import TalentAvatar from './TalentAvatar'
import TalentAction from './TalentAction'
import LocalizedCharacter from './LocalizedCharacter'
import OriginalCharacter from './OriginalCharacter'

// import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete'
// import Autocomplete from './ComboBox'
// import { useDebounce } from '../../lib/hooks'

// const filter = createFilterOptions()


const useStyles = makeStyles(theme => ({
  TitleRoleEditor: {
    padding: `0 ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
    minHeight: 100,
  },
  TitleRoleEditorHeaderRoot: {
    padding: 0.5 * theme.spacing(1),
  },
  TitleRoleEditorHeaderAvatar: {
    // padding: 0.5*theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  TitleRoleEditorHeaderAction: {
    paddingTop: theme.spacing(1),
    minWidth: 48,
  },
  CharacterField: {
    marginLeft: theme.spacing(1),
  },
  Billing: {
    minWidth: 32,
  },
  // ButtonUnclickable: {
  //   '&:hover': {
  //     backgroundColor: theme.palette.primary.main,
  //   },
  // },
  LocalizedTalentInputRoot: {
    '& label': {
      whiteSpace: 'nowrap',
    },
  },
  OriginalTypography: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(1),
  },
  MaskButton: {
    paddingTop: theme.spacing(2),
  },
  TalentContainer: {
    [theme.breakpoints.up('md')]: {
      height: `calc(100% - 48px - 48px)`,
      overflow: 'auto',
    },
  },
  expand: {
    flex: 1,
  },
  masked: {
    backgroundColor: theme.palette.grey[500],
  },
  readOnly: {
    backgroundColor: theme.palette.grey[300],
  },
  override: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.grey[300],
  },
}))

const useStylesOriginalTalent = makeStyles(theme => ({
  OriginalTypography: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(1),
  },
}))

const OriginalTalent = ({original}) => {
  const classes = useStylesOriginalTalent()

  return (
  <Typography align="right" className={classes.OriginalTypography}>{
    "TODO: figure out how to get original talent from original character info"
  }</Typography>
  )
}

const LocalizedTalent = ({
  // original,
  talents,
  readOnly,
  localized,
  localizedOverride,
  onClickSelectTalent,
}) => {
  const classes = useStyles()
  const talentChoice = (localizedOverride && localizedOverride.value && localizedOverride.value.talent) || (localized && localized.talent)
  const talent = talents && talentChoice && talents[talentChoice.id]
  const aka = talent && talent.AKAs && talent.AKAs.find(aka => talentChoice.aka)
  const fullName = aka && aka.value

  return (
<Fragment>
  {localized
    ? (
      <TextField
        fullWidth
        type="input"
        label={'Localized Talent'}
        value={fullName || ''}
        classes={{ root: classes.LocalizedTalentInputRoot }}
        inputProps={{ readOnly: true, disabled: true, }}
        InputProps={{
          startAdornment: (!talent) ? null : (
            <IconButton component={RouterLink} to={`/talent?id=${talent.id}`} size="small">
              <LinkIcon />
            </IconButton>
          ),
          endAdornment: (!onClickSelectTalent) ? null : (
            <IconButton onClick={onClickSelectTalent} size="small" color="primary">
              <EditIcon />
            </IconButton>
          )
        }}
      />
    )
    : (
      <Grid container direction="row" alignItems="center" justify="center">
        <Button fullWidth onClick={onClickSelectTalent} variant="contained" color="secondary" size="small" disabled={readOnly}>SELECT TALENT</Button>
      </Grid>
    )
  }
</Fragment>
  )
}

const useStylesCredit = makeStyles(theme => ({
  Paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  CreditGutter: {
    maxWidth: 48,
    minWidth: 48,
  },
  expand: {
    flex: 1,
  },
  pad: {
    padding: theme.spacing(1),
  },
  readOnly: {
    backgroundColor: theme.palette.grey[300],
  },
}))

const TitleRoleEditor = ({
  title,
  readOnly,
  lang,
  // linkData,
  original,
  characters,
  talents,
  localized,
  localizedOverride,
  onClickSelectTalent,
  onClickDeleteCredit,
  onClickDeleteOverride,
  onClickMask,
  updateLocalizationToCharacterAka,
  updateBillingForLocalizedCredit,
}) => {
  const classes = useStylesCredit()

  const originalCharacterId = drillDown(original, ['character', 'id'])
  const originalCharacterAkaId = drillDown(original, ['character', 'aka'])
  const originalCharacterEntity = characters && originalCharacterId && drillDown(characters, [originalCharacterId])
  const originalAkaEntity = originalCharacterEntity && originalCharacterEntity.AKAs && originalCharacterEntity.AKAs.find(aka => aka.id === originalCharacterAkaId)

  return (
  <Paper
    className={clsx(classes.Paper, readOnly && classes.readOnly)}
    // className={clsx(classes.TitleRoleEditor, localizedOverride && classes.override, (isMaskedTitle || isMaskedOverride) ? classes.masked : readOnly && classes.readOnly)}
    data-credit-original-character-aka={originalCharacterAkaId}
  >
    <Grid container direction="row" wrap="nowrap" justify="flex-start">
      <div className={classes.CreditGutter}>
        <TalentAvatar {...{ title, lang, readOnly, original, localized, localizedOverride, updateBillingForLocalizedCredit }} />
      </div>
      <Grid container direction="column" wrap="nowrap" className={clsx(classes.expand, classes.pad)}>
        <Grid container direction="row" wrap="nowrap" className={classes.expand} alignItems="center">
          <LocalizedCharacter {...{ original, localized, characters, readOnly, lang, updateLocalizationToCharacterAka }} />
        </Grid>
        <Grid container direction="row" wrap="nowrap" className={classes.expand} alignItems="center">
          {original && (
            <OriginalCharacter
              inherited={readOnly}
              label={(originalAkaEntity && drillDown(originalAkaEntity, ['original', 'value'])) || 'Character Missing'}
              id={originalCharacterId}
              aka={originalCharacterAkaId}
            />
          )}
        </Grid>
      </Grid>
      <Grid container direction="column" wrap="nowrap" className={clsx(classes.expand, classes.pad)}>
        <Grid container direction="row" wrap="nowrap" className={classes.expand} alignItems="center">
          <LocalizedTalent {...{ localized, talents, readOnly, localizedOverride, onClickSelectTalent, }} />
        </Grid>
        <Grid container direction="row" wrap="nowrap" className={classes.expand} alignItems="center">
          {false && original && (
            <OriginalTalent {...{original}} />
          )}
        </Grid>
      </Grid>
      <div className={classes.CreditGutter}>
        <TalentAction {...{
          localizedOverride,
          localized,
          readOnly,
          onClickDeleteCredit,
          onClickDeleteOverride,
          onClickMask,
          }} />
      </div>
    </Grid>
  </Paper>
  )
}

export default TitleRoleEditor
