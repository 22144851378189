import { TextField, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  BillingInputInput: {
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
  },
  highlight: {
    backgroundColor: theme.palette.primary.light,
  },
}))

const Billing = ({
  value,
  onChange,
  readOnly,
  highlight,
}) => {
  const classes = useStyles()

  return (
    <TextField
      fullWidth
      type="number"
      variant="standard"
      margin={"dense"}
      inputProps={!readOnly ? {} : {
        readOnly: (true),
        disabled: (true),
      }}
      InputProps={{
        classes: {
          input: clsx(classes.BillingInputInput, highlight && classes.highlight),
        }
      }}
      value={value || ''}
      label="Billing"
      onChange={onChange}
    />
  )
}

export default Billing
