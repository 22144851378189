import React, { useEffect, useRef } from 'react'
import { Search as SearchIcon } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/Clear'
import { Grid, IconButton, Input, fade, makeStyles, Tooltip} from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  searchContainer: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  search: {
    maxWidth: 350,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  searchIcon: {
    color: theme.palette.common.white,
  },
  inputRoot: {
    color: 'inherit',
  //   backgroundColor: 'rgba(255,255,255,0.25)',
  //   width: '100%',
  //   height: '100%',
  //   margin: `0 ${theme.spacing(1)}px`,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
  },
  Clear: {
    color: theme.palette.common.white,
  },
}))

const Clear = ({onClick}) => {
  const classes = useStyles()
  return (
<IconButton
  onClick={onClick}
  aria-label="clear search term"
  className={classes.Clear}
  >
  <ClearIcon />
</IconButton>
  )
}

const SearchInput = ({
  showInput,
  filterTerm,
  onChangeTerm,
  onClickClearTerm,
  onClickExpandInput,
}) => {
  const classes = useStyles()
  // const [shouldFocusInput, setShouldFocusInput] = useState(false)
  // const searchTerm = searchState.term || ''
  // const debouncedSearchTerm = useDebounce(searchTerm, debounceMs)
  const inputRef = useRef()

  // const onBlur = useCallback(() => {
  //   // console.log('SearchInput - onBlur')
  //   setShouldFocusInput(false)
  // }, [setShouldFocusInput])

  // useEffect(() => {
  //   if (shouldFocusInput) {
  //     inputRef.current.focus()
  //   }
  // }, [shouldFocusInput, inputRef])

  useEffect(() => {
    if (showInput) {
      inputRef.current.focus()
    }
  }, [showInput])

  return (
<Grid
  container
  direction="row"
  wrap="nowrap"
  alignItems="center"
  justify="flex-end"
  className={clsx(classes.searchContainer, showInput && classes.search)}
>
  {!showInput ? (
    <Tooltip title="Search Titles">
      <span>
        <IconButton onClick={onClickExpandInput}>
          <SearchIcon className={classes.searchIcon} />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
  <Grid container direction="row" alignItems="center">
    <Input
      fullWidth
      disableUnderline
      placeholder={"Search Titles"}
      type="input"
      inputRef={inputRef}
      value={filterTerm}
      onChange={onChangeTerm}
      // onBlur={onBlur}
      startAdornment={<SearchIcon />}
      endAdornment={<Clear onClick={onClickClearTerm} />}
      inputProps={{ 'aria-label': 'search' }}
      classes={{root: classes.inputRoot}}
    />
  </Grid>
  )
  }
</Grid>
  )
}

export default SearchInput
