
import {
  Button,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import clsx from 'clsx'

import Bcp47Chip from '../Bcp47Chip'

import { formatTitle } from '../../lib/models'

const useStyles = makeStyles(theme => ({
  breathe: {
    margin: `0 ${theme.spacing(1)}px`,
  },
  expand: {
    flex: 1,
  },
  button: {
    minWidth: 100,
  },
  DocumentActions: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  ImportHeader: {
    padding: theme.spacing(2),
  },
}))


const ImportHeader = ({
  titles,
  // mpm,
  // language,
  importedXlsx,
  enableImport,
  targetDetailsState,
  onClickSaveImport,
  onClickCancelImport,
  LMTBcp47ToDescription,
}) => {
  const classes = useStyles()
  const mpm = importedXlsx.data.mpm
  const title = titles && titles[mpm]
  const language = importedXlsx.data.language

  return (
<Grid container direction="row" alignItems="center" justify="flex-start" wrap="nowrap" className={classes.ImportHeader}>
  <Grid container direction="row" alignItems="center" justify="flex-start" wrap="nowrap" className={classes.DocumentActions}>
    <Grid item>
      <Bcp47Chip code={language} description={LMTBcp47ToDescription[language]} />
    </Grid>
  </Grid>
  <Grid container direction="row" alignItems="center" justify="center" wrap="nowrap" className={classes.DocumentActions}>
    <Grid item className={classes.expand}></Grid>
    <Grid item><Typography variant="h6">{(!title && !targetDetailsState.response) ? 'Loading...' : formatTitle(mpm, titles)}</Typography></Grid>
    <Grid item className={clsx(classes.expand, classes.breathe)}>
      <a href={`/mpm/${mpm}`} target="_blank" rel="noopener noreferrer">
        <OpenInNewIcon />
      </a>
    </Grid>
  </Grid>
  <Grid container direction="row" alignItems="center" justify="flex-end" wrap="nowrap" className={classes.DocumentActions}>
    <Button id="import-review-save" onClick={onClickSaveImport} variant="contained" color="secondary" disabled={!enableImport} className={classes.button}>save</Button>
    <Button id="import-review-cancel" onClick={onClickCancelImport} variant="text" color="default" className={classes.button}>cancel</Button>
  </Grid>
</Grid>
  )
}

export default ImportHeader
