
export const parseParams = (str) => {
  const paramsObj = str.split('&').reduce((accum, keyval) => {

    const [key, value] = keyval.split('=')
    return {
      ...accum,
      [key]: value,
    }
  }, {})

  return paramsObj
}

export const makeQueryString = parms => {
  return Object.keys(parms).map(pkey => {
    return `${pkey}=${parms[pkey]}`
  }).join('&')
}

export const clone = obj => JSON.parse(JSON.stringify(obj))


export const removeUrlParam = (paramNameToRemove, windowLocation, windowHistory, documentTitle) => {
  // remove auth code from url (from code grant flow)
  const urlParams = new window.URLSearchParams(windowLocation.search);
  const foundParam = urlParams.get(paramNameToRemove);
  if (foundParam) {
      console.log(`removing query param, [${paramNameToRemove}=${foundParam}]`);
      let newUrl = windowLocation.origin + windowLocation.pathname;
      const otherParams = {};
      urlParams.forEach((value, key) => {
          if (key !== paramNameToRemove) {
              otherParams[key] = value;
          }
      });
      if (Object.keys(otherParams).length > 0) {
          newUrl = newUrl + '?' + makeQueryString(otherParams)
      }
      windowHistory.replaceState({}, documentTitle, newUrl);
  }
}
