import { useCallback } from "react"
import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core"
import { Close, Share } from "@material-ui/icons"
import Filler from '../Filler'
import CharacterAka from './CharacterAka'
import CharacterAkaInputBar from './CharacterAkaInputBar'

const useStyles = makeStyles(theme => ({
  CharacterDetails: {
    height: '100%',
  },
  CharacterDetailsContent: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(1),
  },
}))

const ShareEntity = () => {
  const onClick = useCallback(() => {
    const value = window.location.href
    navigator.clipboard.writeText(value)
  }, [])

  return (
    <Tooltip title="Copy URL to clipboard">
      <IconButton onClick={onClick}>
        <Share color="secondary" />
      </IconButton>
    </Tooltip>
  )
}

const CloseDetails = ({
  onClickCloseDetails,
}) => {
  return (
<Tooltip title="Close character details">
  <IconButton onClick={onClickCloseDetails}>
    <Close />
  </IconButton>
</Tooltip>
  )
}

const DetailsBar = ({
  onClickCloseDetails,
}) => {
  return (
<Grid container direction="row" wrap="nowrap">
  <CloseDetails {...{onClickCloseDetails}} />
  <Filler />
  <ShareEntity />
</Grid>
  )
}

const CharacterDetailsHeader = ({
  onClickCloseDetails,
  selected,
  addAkasToCharacter,
}) => {
  return (
<div>
  <DetailsBar {...{onClickCloseDetails}} />
  <CharacterAkaInputBar {...{
    selected,
    addAkasToCharacter,
  }} />
</div>
  )
}

const CharacterDetailsContent = ({
  character,
  addTitlesToCharacterAka,
  removeTitlesFromCharacterAka,
  updateCharacterAkaOriginal,
  updateLocalizationToCharacterAka,
}) => {
  const classes = useStyles()
  return (
<div className={classes.CharacterDetailsContent}>
  {((character && character.AKAs) || []).map(aka => (
  <CharacterAka
    key={`/character/${character.id}/aka/${aka.id}`}
    {...{
      character,
      aka,
      addTitlesToCharacterAka,
      removeTitlesFromCharacterAka,
      updateCharacterAkaOriginal,
      updateLocalizationToCharacterAka,
    }}  />
))}
</div>
  )
}

const CharacterDetails = ({
  selected,
  onClickCloseDetails,
  updateCharacterAkaOriginal,
  updateLocalizationToCharacterAka,
  addAkasToCharacter,
}) => {
  const classes = useStyles()

  if (!selected) {
    return null
  }

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.CharacterDetails}>
      <CharacterDetailsHeader {...{
        onClickCloseDetails,
        selected,
        addAkasToCharacter,
      }} />
      <CharacterDetailsContent {...{
        character: selected,
        updateCharacterAkaOriginal,
        updateLocalizationToCharacterAka,
      }} />
    </Grid>
  )
}

export default CharacterDetails
