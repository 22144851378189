import React from 'react'

import {
  CircularProgress,
  Grid,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core'

import TalentItem from './TalentSelectItem'

const useStyles = makeStyles(theme => ({

}))


const DisplayMessage = ({text, spinner}) => {
  return (
<Grid container direction="row" alignItems="center">
  <Typography>{text}</Typography>
  {spinner && <CircularProgress color="primary" />}
</Grid>
  )
}

const TalentSelectContent = ({
  disabled,
  onClickSelectExistingTalent,
  selectedTalentId,
  sortedTalent,
  original,
  localized,
}) => {
  const classes = useStyles()
  // const showSearchResults = (searchState.term && searchState.term.length > 0 && searchState.results.length > 0)

  return (
<div className={classes.TalentEditorContent}>
  {(!sortedTalent || sortedTalent.length === 0) && <DisplayMessage text="No talent matches filter" />}
  {/* {searchState.fetching && <DisplayMessage text="Searching..." spinner={true} />} */}
  {/* {(!searchState.fetching && searchState.term && searchState.term.length > 0 && searchState.results.length === 0) && <DisplayMessage text="No results in search" />} */}
  {/* <div className={classes.TalentEditorContentList}> */}
  {(!sortedTalent || sortedTalent.length > 0)
  && <List>
    {(sortedTalent || []).map(talent => (
      <TalentItem
        disabled={disabled}
        onClickSelect={onClickSelectExistingTalent({talent, original, localized})}
        talent={talent}
        selected={selectedTalentId === talent.id}
        key={`/role-editor/talent-option/${talent.id}`}
      />
    ))}
  </List>}
  {/* </div> */}
</div>
  )
}

export default TalentSelectContent