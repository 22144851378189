
import {
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
}))

const EmptyList = ({
  label="empty list"
}) => {
  const classes = useStyles()
  return (
  <Grid container direction="row" justify="center" alignItems="center" className={classes.fullHeight}>
    <Typography variant="h4">{label}</Typography>
  </Grid>
  )
}

export default EmptyList
