import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const DropDown = ({
  id,
  name,
  label,
  className,
  value,
  onChange,
  options,
  disabled,
}) => {
  return (
<FormControl variant="filled" className={className}>
  {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
  <Select
    native
    fullWidth
    disabled={disabled}
    value={value}
    onChange={onChange}
    inputProps={{
      name,
      id,
    }}
  >
    {(options || []).map(o => (
      <option key={`dropdown/${id}/option/${o.value}`} value={o.value}>{o.display}</option>
    ))}
  </Select>
</FormControl>
  )
}

export default DropDown
