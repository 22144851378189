import { Grid, makeStyles, Typography, List, } from '@material-ui/core'

import TalentSearchResultItem from './TalentSearchResultItem'
import TalentSearchHeader from './TalentSearchHeader'
import FullHeight from '../FullHeight'


const useStyles = makeStyles(theme => ({
  expand: {
    flex: 1,
  },
  TalentSearchResults: {
    padding: theme.spacing(1),
  },
  TalentSearchResultsList: {
    flex: 1,
    overflow: 'auto',
  },
  TalentItem: {

  },
  TalentEditorAddForm: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  TalentItemActions: {
    textAlign: 'right',
    flex: 1,
  },
}))

const DisplayMessage = ({text}) => {
  return (
<Grid container direction="row" alignItems="center">
  <Typography>{text}</Typography>
</Grid>
  )
}

const TalentSearchResults = ({
  searchResults,
  selected,
  onClickSelect,
}) => {
  const classes = useStyles()
  const sortedTalent = (searchResults && searchResults.results) || []

  return (
<div className={classes.TalentSearchResults}>
  {(!searchResults.fetching && sortedTalent.length === 0) && <DisplayMessage text="No search results" />}
  {searchResults.fetching && <DisplayMessage text="Searching..." />}
  {/* {(!searchState.fetching && searchState.term && searchState.term.length > 0 && searchState.results.length === 0) && <DisplayMessage text="No results in search" />} */}
  {(sortedTalent.length > 0) && (
    <List className={classes.TalentSearchResultsList}>
      {sortedTalent.map(t => (
        <TalentSearchResultItem
          onClickSelect={onClickSelect(t)}
          talent={t}
          isSelected={selected && (selected.id === t.id)}
          key={`/talent-editor/search-result/${t.id}`}
        />
      ))}
    </List>
  )}
</div>
  )
}


const TalentSearch = ({
  searchResults,
  inputValue,
  onClickSelect,
  onClickClearSearch,
  onChangeInputValue,
  onClickAddTalent,
  selected,
}) => {
  return (
  <FullHeight>
    <TalentSearchHeader {...{
      inputValue,
      onChangeInputValue,
      onClickClearSearch,
      onClickAddTalent,
    }} />
    <TalentSearchResults {...{
      searchResults,
      selected,
      onClickSelect,
    }} />
  </FullHeight>
  )
}

export default TalentSearch
