import React from 'react'
import { Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  item: {
    height: 65,
    width: '100%',
    textAlign: 'center',
  },
}))

const LoadingScreen = () => {
  const classes = useStyles()
  return (
<Grid container direction="column" justify="center" alignItems="center" className={classes.fullHeight}>
  <Grid item className={classes.item}><Typography variant="h4">Loading</Typography></Grid>
  <Grid item className={classes.item} ><CircularProgress color="primary" /></Grid>
  <Grid item className={classes.item}></Grid>
</Grid>
  )
}

export default LoadingScreen
