import { makeQueryString } from "./lib/query-params";

import awsExports from './aws-exports'

console.log(`--- using amplify env [${process.env.REACT_APP_BUILD_ENV}]`)

const envs = {
  dev: {
    domainPrefix: 'dev-l12n-msc',
    appClientId: '7pfpnvaaprpamt9nte3qd9jo5o',
    idp_identifier: 'oktasaml',
    identityPoolId: 'us-west-2:64ba7a9e-1a99-4eea-8245-f4f3589c7862',
    userPoolId: 'us-west-2_7TTXi2XkH',
  },
  qa: {
    domainPrefix: 'qa-l12n-msc',
    appClientId: '44hm9rf10aa0mrc7hocrb89h3q',
    idp_identifier: 'oktasaml',
    identityPoolId: 'us-west-2:9e52e473-c811-4b50-8d9f-e30a0254ae8f',
    userPoolId: 'us-west-2_AsR0OHDLq',
  },
  prod: {
    domainPrefix: 'prod-l12n-msc',
    appClientId: '59n35vs5fieolviehnivuoma2e',
    idp_identifier: 'oktasaml',
    identityPoolId: 'us-west-2:d7b7b64c-1fb0-4fc6-a6b3-ef81440623fc',
    userPoolId: 'us-west-2_T6AHTn5Pn',
  },
}

const cognito = {
  region: awsExports.aws_project_region, //process.env.REACT_APP_COGNITO_REGION,
  ...envs[process.env.REACT_APP_BUILD_ENV],
  // domainPrefix: envs[process.env.REACT_APP_BUILD_ENV].domainPrefix, //process.env.REACT_APP_COGNITO_DOMAIN_PREFIX,
  // appClientId: envs[process.env.REACT_APP_BUILD_ENV].appClientId, //process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  // idp_identifier: envs[process.env.REACT_APP_BUILD_ENV].idp_identifier, //process.env.REACT_APP_COGNITO_IDP_IDENTIFIER,
  // identityPoolId: envs[process.env.REACT_APP_BUILD_ENV].identityPoolId, //process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
};

console.log('---cognito settings', cognito)


const oauth = {
  // Domain name
  domain: `${cognito.domainPrefix}.auth.${cognito.region}.amazoncognito.com`,

  // Authorized scopes
  scope: ['openid', 'aws.cognito.signin.user.admin',
    // 'profile',
    // 'phone',
    'email',
  ],

  // Callback URL
  redirectSignIn: window.location.origin, //'https://ops-dev.contentnow.warnerbros.com', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
  // redirectSignIn: 'http://localhost:3000', //window.location.href,

  // Sign out URL
  redirectSignOut: window.location.origin, // 'https://ops-dev.contentnow.warnerbros.com', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

  // 'code' for Authorization code grant, 
  // 'token' for Implicit grant
  responseType: 'code',
  // responseType: 'token',

  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true
  }
};

const config = {
  app: {
    prefersDarkMode: false,
    preferredRendition: 'MarsExtend',
    // ref:
    // https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
    // TODO: must we encodeUri(loginUrl) ?
    // https://openid.net/specs/openid-connect-core-1_0.html#ImplicitAuthorizationEndpoint
    loginURL: window.encodeURI(`https://${oauth.domain}/authorize?${makeQueryString({
      response_type: 'code',
      redirect_uri: oauth.redirectSignIn,  // window.location.href,
      client_id: cognito.appClientId,
      identity_provider: cognito.idp_identifier,
      scope: oauth.scope.join(' '),
    })}`),
  },
  Amplify: {
    ...awsExports,
    // aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_URL,
    // aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
    // aws_appsync_authenticationType: 'AWS_IAM',
    Auth: {
      identityPoolId: cognito.identityPoolId, //process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      region: cognito.region, //process.env.REACT_APP_COGNITO_REGION,
      userPoolId: cognito.userPoolId, //process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: cognito.appClientId, //process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      oauth,
    }
  }
}

// console.log('loginUrl', config.app.loginURL)

export default config
