import {Fragment, useCallback, useEffect, useState} from 'react'

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core'

import TitleCharacter from './TitleCharacter'
import AtomImportContent from './AtomImportContent'
import CharacterViewHeader from './CharacterViewHeader'

import ExpandContainer from '../ExpandContainer'
import EmptyList from '../EmptyList'

import { drillDown, indexByKey, unwindByKey } from 'deepdown'

import {
  reducerRankMatchCandidates,
  reducerComputeBehavior,
  reducerImportedAtomCharactersToApiBehaviors,
} from '../../lib/models'

import {bulk} from 'dubcard'

const { BehaviorMode } = bulk

const selectCharacterIdFromCandidates = ['character', 'id']

const useStyles = makeStyles(theme => ({
  PageSection: {
    margin: theme.spacing(1),
  },
}))


const TitleCharacters = ({
  characterCredits,
  title,
  characters,
  updateBillingForOriginalCharacterCredit,
  removeTitlesFromCharacterAka,
}) => {
  const onClickDeleteCredit = useCallback(({title, credit}) => () => {
    console.log('TitleCharacters/onClickDeleteCredit', title, credit)
    const input = {
      id: credit.character.id,
      akaId: credit.character.aka,
      mpm: [title.mpm],
    }
    removeTitlesFromCharacterAka(input).then(result => {
      console.log('TitleCharacters/onClickDeleteCredit, success', result)
    }).catch(error => {
      console.log('TitleCharacters/onClickDeleteCredit, error', error)
    })
  }, [removeTitlesFromCharacterAka])

  return (
<Fragment>
  {
    characterCredits.map(credit => {
      const charId = credit && drillDown( credit, ['character', 'id'])
      const character = characters && characters[charId]
      return (
      <TitleCharacter {...{
        key: `title/original/character/${charId}`,
        title,
        credit,
        character,
        updateBillingForOriginalCharacterCredit,
        onClickDeleteCredit: onClickDeleteCredit({title, credit}),
      }} />)
    })
  }
</Fragment>
  )
}

const ExistingCharacter = ({
  character,
  disabled,
  onClickSelectExistingCharacter,
}) => {
  const aka = character.AKAs[0]

  return (
  <ListItem button onClick={onClickSelectExistingCharacter} disabled={disabled}>
    <ListItemText primary={aka.original.value} />
  </ListItem>
  )
}

const FranchiseCharacters = ({
  original,
  franchiseCharacters,
  onClickSelectExistingCharacter,
}) => {
  const creditsByCharacterId = (original && indexByKey(original.credits, ['character', 'id'])) || {}
  return (
<List>
  {(franchiseCharacters || []).map(character => {
    const disabled = !!drillDown(creditsByCharacterId, [character.id])
    return (
      <ExistingCharacter
        key={`/detail/original/existing-character/${character.id}`}
        disabled={disabled}
        onClickSelectExistingCharacter={onClickSelectExistingCharacter(character)}
        character={character}
      />
    )
  })}
</List>
  )
}

const CharacterView = ({
  editorLists,
  characters,
  franchiseCharacters,
  title,
  original,
  createCharacter,
  addTitlesToCharacterAka,
  removeTitlesFromCharacterAka,
  updateBillingForOriginalCharacterCredit,
  getAtomCharacters,
  importCharactersFromAtom,
  minScore = 0.6,
}) => {
  const classes = useStyles()
  const [filter, setFilter] = useState({query: '', fetching: false})
  const [showFranchise, setShowFranchise] = useState(false)
  const [showAtomImport, setShowAtomImport] = useState(false)
  const [atomImportData, setAtomImportData] = useState({})
  const [matchCandidates, setMatchCandidates] = useState({})
  const [atomCharacters, setAtomCharacters] = useState({fetching: false, query: null, results: null, error: null})
  const [atomImport, setAtomImport] = useState({fetching: false, query: null, results: null, error: null})

  const atomImportNeedsInput = ((atomCharacters && atomCharacters.results) || []).reduce((accum, atomCharacter) => {
    return accum || (
      (drillDown(atomImportData, [atomCharacter.characterName, 'ignore']) !== true)
      && (drillDown(atomImportData, [atomCharacter.characterName, 'mode']) === BehaviorMode.USE_EXISTING)
      && !drillDown(atomImportData, [atomCharacter.characterName, 'character', 'id'])
    )
  }, false)

  const atomImportDataEmpty = Object.keys(atomImportData).reduce((accum, characterName) => {
    const {ignore} = atomImportData[characterName]
    return accum && ignore
  }, true)

  const characterCredits = (editorLists && editorLists.title && editorLists.title.sortedOriginalCharacters) || []
  const atomImportDisabled = atomImportNeedsInput || atomImport.fetching || !!atomImport.error || atomImportDataEmpty

  useEffect(() => {
    // length M
    const unwoundAkas = (franchiseCharacters && unwindByKey(franchiseCharacters, ['AKAs'])) || []

    // N x M complexity
    const candidateMatches = (atomCharacters.results || []).reduce(reducerRankMatchCandidates(unwoundAkas), {})
    setMatchCandidates(candidateMatches)

    // N x 1 complexity
    const importData = (atomCharacters.results || []).reduce(reducerComputeBehavior({matchCandidates: candidateMatches, minScore}), {})

    setAtomImportData(importData)
  }, [atomCharacters.results, franchiseCharacters, minScore])

  useEffect(() => {
    const query = {mpm: [title.mpm]}
    if (atomCharacters.fetching || (
      query.mpm[0] === drillDown(atomCharacters, ['query', 'mpm', 0])
    )) {
      return
    }

    setAtomCharacters({fetching: true, query})
    getAtomCharacters(query).then(results => {
      console.log(`CharacterView/useEffect/getAtomCharacters, response: ${JSON.stringify(results)}`)
      setAtomCharacters(state => ({...state, fetching: false, results}))
    }).catch(error => {
      console.error(`CharacterView/useEffect/getAtomCharacters, query (${JSON.stringify(query)}), error: ${JSON.stringify(error)}`)
      setAtomCharacters(state => ({...state, fetching: false, error}))
    })
  }, [title, getAtomCharacters, atomCharacters, setAtomCharacters])

  const onClickIgnoreItem = useCallback(data => () => {
    const key = data.characterName

    setAtomImportData(x => ({
      ...x,
      [key]: {
        ...(x[key] || {}),
        ignore: x[key] ? !x[key].ignore : true,
      },
    }))
  }, [setAtomImportData])

  const onChangeMode = useCallback(data => e => {
    const key = data.characterName

    // console.log(`OriginalTitleView/CharacterView/onChangeMode[${key}], value =`, e.target.value)

    setAtomImportData(x => ({
      ...x,
      [key]: {
        ...(x[key] || {}),
        mode: e.target.value,
      },
    }))
  }, [setAtomImportData])

  const onChangeCharacter = useCallback(data => e => {
    const key = data.characterName

    // console.log(`OriginalTitleView/CharacterView/onChangeCharacter[${key}], value =`, e.target.value)
    const candidates = matchCandidates[key]

    const selected = candidates.find(c => drillDown(c, selectCharacterIdFromCandidates) === e.target.value)
    if (!selected) {
      console.error(`OriginalTitleView/CharacterView/onChangeCharacter - did not identify user choice [${e.target.value}] from candidates: ${JSON.stringify(candidates)}`)
      return
    }

    const {character} = selected

    setAtomImportData(x => ({
      ...x,
      [key]: {
        ...(x[key] || {}),
        character,
      },
    }))
  }, [setAtomImportData, matchCandidates])

  const onChangeFilterQuery = useCallback(e => {
    setFilter(previous => ({ ...previous, query: e.target.value }))
  }, [setFilter])

  const onClickShowAtomImport = useCallback(() => {
    setShowAtomImport(true)
  }, [setShowAtomImport])

  const onClickCancelAtomImport = useCallback(() => {
    setShowAtomImport(false)
    setAtomImport({})
  }, [setShowAtomImport])

  const onClickShowFranchise = useCallback(() => {
    setShowFranchise(true)
  }, [setShowFranchise])

  const onClickCancelAddCharacter = useCallback(() => {
    setShowFranchise(false)
    setFilter(previous => ({ ...previous, query: '' }))
  }, [setShowFranchise, setFilter])

  const onClickImportFromAtom = useCallback(() => {
    console.log('CharacterView/onClickImportFromAtom', atomImportData)

    const input = {
      mpm: title.mpm,
      behaviors: Object.keys(atomImportData).reduce(reducerImportedAtomCharactersToApiBehaviors(atomImportData), []),
    }

    setAtomImport({fetching: true, query: input})
    importCharactersFromAtom(input).then(results => {
      console.log('CharacterView/onClickImportFromAtom/importCharactersFromAtom, results', results)
      setAtomImport(d => ({...d, fetching: false, results, error: null}))
      setShowAtomImport(false)
    }).catch(error => {
      console.log('CharacterView/onClickImportFromAtom/importCharactersFromAtom, error', error)
      setAtomImport(d => ({...d, fetching: false, results: null, error}))
    // setShowAtomImport(false)
    })
  }, [title.mpm, atomImportData, importCharactersFromAtom, setAtomImport])

  const onClickAddNewCharacter = useCallback(() => {
    console.log('onClickAddNewCharacter', filter.query)
    const value = filter.query
    const language = title.original.language

    const model = {
      AKAs: [
        {mpm: [title.mpm], original: {language, value}}
      ]
    }

    createCharacter(model).then(result => {
      console.log('CharacterView/onClickAddNewCharacter/createCharacter, result:', result)
      setFilter({query: ''})
      setShowFranchise(false)
    }).catch(error => {
      console.error('CharacterView/onClickAddNewCharacter/createCharacter, error', error)
    })
  }, [title, filter, setFilter, setShowFranchise, createCharacter])

  const onClickSelectExistingCharacter = useCallback(character => () => {
    console.log('CharacterView/onClickSelectExistingCharacter', character)
    addTitlesToCharacterAka({id: character.id, akaId: character.AKAs[0].id, mpm: [title.mpm]}).then(result => {
      console.log('CharacterView/onClickSelectExistingCharacter, result : ', result)
      setFilter({query: ''})
      setShowFranchise(false)
    }).catch(error => {
      console.error('CharacterView/onClickSelectExistingCharacter, error : ', error)
    })
  }, [addTitlesToCharacterAka, title.mpm])

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.PageSection}>
      <CharacterViewHeader {...{
        atomImportDisabled,
        onClickImportFromAtom,
        characterCredits,
        filter,
        showFranchise,
        showAtomImport,
        onClickShowAtomImport,
        onClickCancelAtomImport,
        onChangeFilterQuery,
        onClickAddNewCharacter,
        onClickShowFranchise,
        onClickCancelAddCharacter,
      }} />
      <ExpandContainer>
        {(showAtomImport)
        ? (
          <AtomImportContent {...{
            franchiseCharacters,
            atomCharacters,
            atomImport,
            minScore,
            atomImportData,
            onClickIgnoreItem,
            onChangeMode,
            onChangeCharacter,
          }} />
        )
        : (showFranchise)
          ? <FranchiseCharacters {...{
              original,
              franchiseCharacters,
              onClickSelectExistingCharacter,
          }} />
          : (characterCredits.length === 0)
            ? <EmptyList label="No Characters Yet" />
            : <TitleCharacters {...{
                characterCredits,
                title,
                characters,
                updateBillingForOriginalCharacterCredit,
                removeTitlesFromCharacterAka,
              }} />
        }
      </ExpandContainer>
    </Grid>
  )
}

export default CharacterView
