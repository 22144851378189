
import {useCallback, useEffect, useState} from 'react'

import {Link as RouterLink} from 'react-router-dom'

import {
  Link as LinkIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'

import {
  Grid,
  IconButton,
  makeStyles,
  Paper,
} from '@material-ui/core'

import Billing from '../editor/Billing'
import DropDown from '../DropDown'

import { drillDown } from 'deepdown'
import { equivalentBilling } from '../../lib/models'

const useStylesOriginalCharacter = makeStyles(theme => ({
  // CharacterField: {
  //   marginLeft: theme.spacing(1),
  // },
  Billing: {
    minWidth: 48,
    maxWidth: 48,
    margin: `0 ${theme.spacing(1)}px`,
  },
  Paper: {
    margin: theme.spacing(1),
  },
  expand: {
    flex: 1,
  },
}))

const TitleCharacter = ({
  title,
  credit,
  character,
  updateBillingForOriginalCharacterCredit,
  onClickDeleteCredit,
}) => {
  const classes = useStylesOriginalCharacter()
  const [billing, setBilling] = useState(credit && credit.billingOrder)
  const options = ((character && character.AKAs) || []).map(aka => ({value: aka.id, display: aka.original.value}))
  const akaForTitle = credit && credit.character && credit.character.aka
  const billingLocalized = credit && credit.billingOrder
  const characterId = credit && drillDown(credit, ['character', 'id'])
  const localizationsUsingCharacter = (title.localized || []).filter(loc => {
    // don't count the original language
    if (loc.language === title.original.language) {
      return false
    }
    // check localized languages
    const creditsUsingCharacter = (loc.credits || []).filter(c => (characterId === drillDown(c, ['character', 'id'])))
    return creditsUsingCharacter.length > 0
  })
  const deleteDisabled = (localizationsUsingCharacter.length > 0)

  const onChangeAka = useCallback(evt => {
    console.log('TODO: TitleCharacter/onChangeAka', evt.target.value)
  }, [])

  const onChangeBilling = useCallback(evt => {
    console.log('TODO: TitleCharacter/onChangeBilling', evt.target.value)
    setBilling(evt.target.value)
  }, [setBilling])

  useEffect(() => {
    if (!title || !credit || equivalentBilling({billing, billingLocalized})) {
      return
    }

    const input = {
      mpm: title.mpm,
      language: title.original.language,
      credit: {
        character: {id: credit.character.id},
        billingOrder: (!billing || billing.length===0) ? null : parseInt(billing, 10),
      }
    }
    updateBillingForOriginalCharacterCredit(input).then(result => {
      console.log('TitleCharacter/useEffect/updateBillingForOriginalCharacterCredit, result', result)
    }).catch(error => {
      console.error('TitleCharacter/useEffect/updateBillingForOriginalCharacterCredit, error', error)
    })
  }, [title, credit, billing, billingLocalized, updateBillingForOriginalCharacterCredit])

  return (
<Paper className={classes.Paper}>
  <Grid container direction="row" wrap="nowrap">
    <div className={classes.Billing}>
      <Billing {...{onChange: onChangeBilling, value: billing}} />
    </div>
    <IconButton component={RouterLink} to={`/character?id=${credit.character.id}`} size="small">
      <LinkIcon />
    </IconButton>
    <DropDown {...{
      options,
      onChange: onChangeAka,
      value: !akaForTitle ? -1 : akaForTitle.id,
      label: 'AKA',
      }} />
    <span className={classes.expand}></span>
    <IconButton
      size="small"
      onClick={onClickDeleteCredit}
      color="primary"
      disabled={deleteDisabled}
    >
      <DeleteIcon />
    </IconButton>
  </Grid>
</Paper>
  )
}

export default TitleCharacter
