import React, { Fragment } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import config from '../config'
import Auth from '@aws-amplify/auth'

import {
  Divider,
  List,
  ListItem,
  // ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
} from '@material-ui/core'

// import {
//   // AccountCircleOutlined,
//   // ExitToApp,
//   // HomeOutlined,
//   // LockOpenOutlined,
//   ExpandLess,
//   ExpandMore,
// } from '@material-ui/icons'

import { episodeSort, } from '../lib/models'
import { parseParams, } from '../lib/query-params'

import { drillDown, indexByKey } from 'deepdown'
import {
  selectMpmFromTitle,
  selectSeasonOrEpisodeFromTitleMetadata,
  selectOriginalTitleFromTitle,
} from '../lib/selections'


const useStyles = makeStyles(theme => ({
  SubHeader: {
    padding: theme.spacing(1),
  },
  expand: {
    flex: 1,
  },
  ListItemNav: {
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    // hack to show selected color
    // https://github.com/mui-org/material-ui/issues/13672
    // may need more understanding of
    // https://material-ui.com/customization/components/#pseudo-classes
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  // does not work, requires above hack
  ListItemNameSelected: {
    backgroundColor: theme.palette.primary.main,
  }
}))

const ListItemLink = ({ to, children }) => {
  const handleClick = () => {
    console.log('ListItemLink to', to)
    window.location = to
  }

  return (
    <ListItem button onClick={handleClick} >
      {children}
    </ListItem>
  )
}

const ListItemNav = ({ selected, to, state, children }) => {
  const classes = useStyles()
  return (
    <ListItem
      selected={selected}
      component={RouterLink}
      to={to}
      state={state}
      classes={{root: classes.ListItemNav, selected: classes.ListItemNameSelected}}
      >
      {children}
    </ListItem>
  )
}


const useStylesMenu = makeStyles(theme => ({
  list: {
    width: 250,
  },
  avatar: {
    textTransform: 'uppercase',
  },
  chipContainer: {
    margin: 2,//'0 2px',
  },
  languageOptionContainer: {
    padding: theme.spacing(1),
  },
}))

const onLogout = (AuthApi) => (/*e*/) => {
  AuthApi.signOut().then(data => {
    console.log('signOut response:', JSON.stringify(data))
  }).catch(error => {
    console.error('signOut error:', JSON.stringify(error))
  })
}

const Logout = ({/*icon=<ExitToApp />,*/ text = "Logout" }) => {
  return (
    <ListItem button onClick={onLogout(Auth)}>
      <ListItemText primary={text} />
    </ListItem>
  )
}

const Home = ({selected}) => {
  return (
    <ListItemNav to="/" selected={selected} >
      <ListItemText primary="Home" />
    </ListItemNav>
  )
}

const Character = ({selected}) => {
  return (
    <ListItemNav to="/character" selected={selected} >
      <ListItemText primary="Character" />
    </ListItemNav>
  )
}

const Talent = ({selected}) => {
  return (
    <ListItemNav to="/talent" selected={selected} >
      <ListItemText primary="Talent" />
    </ListItemNav>
  )
}

const Login = ({to}) => {
  console.log('SideMenu Login to', config.app.loginURL)
  return (
    <ListItemLink to={to} >
      <ListItemText primary="Login" />
    </ListItemLink>
  )
}

const TitleItems = ({ currentMpm, items, itemUrl, itemDisplay, subheader }) => {
  const sorted = items.sort(episodeSort)

  return (
    <Fragment>
      <Divider />
      <List dense subheader={
        <ListSubheader>{subheader}</ListSubheader>
      }>
        {sorted.map(episode => {
          const route = itemUrl(episode)
          const display = itemDisplay(episode)
          const mpm = drillDown(episode, selectMpmFromTitle)

          return (
            <ListItemNav
              key={`/nav/items/${mpm}`}
              selected={currentMpm === mpm}
              to={route}
            >
              <ListItemText primary={display} />
            </ListItemNav>
          )
        })}
      </List>
    </Fragment>
  )
}

const FamilyTitleFilter = (options={}) => (childTitle) => {
  const { types } = options

  // optional check
  if (types) {
    if (!types.includes(childTitle.type)) {
      return false
    }
  }

  // always check
  return childTitle.originallyAiredAs
}

const SideMenu = ({
  user,
  location,
  titles,
  mpm,
  toggleDrawer,
  loginURL=config.app.loginURL,
}) => {
  const classes = useStylesMenu()
  // const [expandedLanguage, setExpandedLanguage] = useState(true)

  // const onClickExpandLanguage = useCallback(e => {
  //   setExpandedLanguage(!expandedLanguage)
  // }, [expandedLanguage, setExpandedLanguage])

  const queryParams = (location.search && location.search.length > 1) ? parseParams(location.search.slice(1)) : {}
  const lang = queryParams.lang

  const itemUrl = episode => {
    const mpm = drillDown(episode, selectMpmFromTitle)
    return `/mpm/${mpm}${!lang ? '' : `?lang=${lang}`}`
  }

  const itemDisplay = prefix => episode => {
    return `${prefix} ${drillDown(episode, selectSeasonOrEpisodeFromTitleMetadata)}`
  }

  const episodeDisplay = prefix => episode => {
    return `${prefix}${drillDown(episode, selectSeasonOrEpisodeFromTitleMetadata)}: ${drillDown(episode, selectOriginalTitleFromTitle)}`
  }

  const flatTitles = !mpm ? [] : Object.keys(titles).map(k => titles[k])
  const indexTitlesByProduct = indexByKey(flatTitles, ['mpmProductNumber'])
  const indexTitlesByFamily = indexByKey(flatTitles, ['mpmFamilyNumber'])
  const episodes = (
    // current=season, filter child episodes
    indexTitlesByProduct[mpm]
    // current=episode/pilot, filter sibling episodes/pilot
    || (
      titles[mpm]
      && titles[mpm].mpmProductNumber
      && indexTitlesByProduct[titles[mpm].mpmProductNumber].filter(FamilyTitleFilter({currentTitle: titles[mpm]}))
    )
  ) || []
  const seasons = (titles[mpm] && (
    titles[mpm].type === 'Series'
    // current=series, filter season children
    ? (indexTitlesByFamily[mpm] || []).filter(FamilyTitleFilter({currentTitle: titles[mpm], types: ['Season']}))
    // current=episode/pilot, filter season parent
    : titles[titles[mpm].mpmProductNumber] && [titles[titles[mpm].mpmProductNumber]])
  ) || []
  const series = titles[mpm] && titles[mpm].mpmFamilyNumber && titles[titles[mpm].mpmFamilyNumber]

  return (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} >
      <List>
        {!user ? <Login to={loginURL} /> : <Logout />}
        {!user ? null : <Home selected={location.pathname === '/'} />}
        {!user ? null : <Character selected={location.pathname === '/character'} />}
        {!user ? null : <Talent selected={location.pathname === '/talent'} />}
      </List>
      <Divider />
      {series && <Divider />}
      {series && <ListItemNav to={`/mpm/${series.mpm}${!lang ? '' : `?lang=${lang}`}`}><ListItemText>Series</ListItemText></ListItemNav>}
      {seasons.length > 0 && <TitleItems currentMpm={mpm} items={seasons} itemUrl={itemUrl} itemDisplay={itemDisplay('Season')} subheader="Seasons" />}
      {episodes && (episodes.length > 0) && <TitleItems currentMpm={mpm} items={episodes} itemUrl={itemUrl} itemDisplay={episodeDisplay('E')} subheader="Episodes" />}
    </div >
  )
}

export default SideMenu
