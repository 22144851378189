import React from 'react'

import {
  makeStyles
} from '@material-ui/core'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  expand: {
    flex: 1,
  }
}))

const ExpandContainer = ({children, classes}) => {
  const classesInternal = useStyles()

  return (
<div className={clsx(classesInternal.expand, classes)}>
  {children}
</div>
  )
}

export default ExpandContainer
