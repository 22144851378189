import React from 'react'
import { Button } from '@material-ui/core'

const LinkButton = ({id, label, to}) => {
  return (
<Button id={id} component="a" href={to} variant="contained" color="secondary">{label}</Button>
  )
}

export default LinkButton
