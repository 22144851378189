import {Link as RouterLink} from 'react-router-dom'

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core'

import Filler from '../Filler'
import ExpandContainer from '../ExpandContainer'
import EmptyList from '../EmptyList'


const useStylesListItem = makeStyles(theme => ({
  ListItemNav: {
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    // hack to show selected color
    // https://github.com/mui-org/material-ui/issues/13672
    // may need more understanding of
    // https://material-ui.com/customization/components/#pseudo-classes
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const LocalizationsViewHeader = () => {
  return (
<Grid container direction="row" wrap="nowrap">
  <Tabs
    value={0}
    indicatorColor="primary"
    textColor="primary"
  >
    <Tab label="Localizations" />
  </Tabs>
  <Filler />
</Grid>
  )
}

const formatLabel = (language, LMTBcp47ToDescription) => `${LMTBcp47ToDescription[language]} (${language})`

const LocalizationListItem = ({
  mpm,
  localized,
  LMTBcp47ToDescription,
}) => {
  const classes = useStylesListItem()
  return (
<ListItem
  component={RouterLink}
  to={`/mpm/${mpm}?lang=${localized.language}`}
  classes={{root: classes.ListItemNav}}
>
  <ListItemText primary={formatLabel(localized.language, LMTBcp47ToDescription)} />
</ListItem>
  )
}

const LocalizationsViewContent = ({
  mpm,
  localizations,
  LMTBcp47ToDescription,
}) => {
  return (
<List>
  {localizations.map(localized => (
    <LocalizationListItem
      key={`/original-view/localized/${localized.language}`}
      {...{
        mpm,
        localized,
        LMTBcp47ToDescription,
      }}
    />
  ))}
</List>
  )
}

const LocalizationsView = ({
  mpm,
  localizations,
  LMTBcp47ToDescription,
}) => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <LocalizationsViewHeader />
      <ExpandContainer>
        {(!localizations || localizations.length === 0)
          ? <EmptyList label="No Localizations Yet" />
          : (
            <LocalizationsViewContent {...{
              localizations,
              mpm,
              LMTBcp47ToDescription,
            }} />
          )
        }
      </ExpandContainer>
    </Grid>
  )
}

export default LocalizationsView
