import {useCallback} from 'react'
import { drillDown, assignByKey } from 'deepdown'
import { Grid, TextField, makeStyles } from '@material-ui/core'

const selectWidth = 'width'.split('.')
const selectHeight = 'height'.split('.')
const selectBgColor = 'bgFill'.split('.')
const selectTextColor = 'textFill'.split('.')

const selectRoleSpacing = 'roleSep'.split('.')

const selectHeadingFontSize = 'headingFontSize'.split('.')
const selectTalentFontSize = 'talentFontSize'.split('.')
const selectRoleVerticalPad = 'roleVerticalPad'.split('.')
const selectBorderVerticalPad = 'verticalPad'.split('.')
const selectHeadingVerticalPad = 'headingVerticalPad'.split('.')
const selectSectionVerticalPad = 'sectionVerticalPad'.split('.')

const useStyles = makeStyles(theme => ({
  SimpleInput: {
    paddingRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
  },
}))

const SimpleInput = ({label, value, onChange, type="number"}) => {
  const classes = useStyles()
  return <TextField className={classes.SimpleInput} label={label} value={value} onChange={onChange} type={type} />
}

const PreviewSettings = ({settings={}, setSettings}) => {

  // console.log('PreviewSettings - settings', settings)
  const handleChange = useCallback(({selection, type='number'}={}) => e => {
    const newValue = type !== 'number' ? e.target.value : parseInt(e.target.value, 10)

    setSettings(oldSettings => {
      // VERY IMPORTANT - clone the settings object or else React Hooks will not cause re-render with newSettings
      const newSettings = {...oldSettings}
      assignByKey(newSettings, selection, newValue)
      // console.log('handleChange - newSettings', drillDown(newSettings, selection))
      return newSettings
    })
  }, [setSettings])

  const handleScreenToFraction = useCallback(({selection, multiplier}) => e => {
    const parsedValue = parseInt(e.target.value, 10)
    const newValue = (1.0/multiplier)*parsedValue

    setSettings(oldSettings => {
      // VERY IMPORTANT - clone the settings object or else React Hooks will not cause re-render with newSettings
      const newSettings = {...oldSettings}
      assignByKey(newSettings, selection, newValue)
      // console.log('handleChange - newSettings', drillDown(newSettings, selection))
      return newSettings
    })
  }, [setSettings])

  const fractionToScreen = (multiplier, floatNumber) => {
    const intNumber = Math.floor(multiplier * floatNumber)
    // console.log('intNumber', intNumber)
    return intNumber
  }

  const width = drillDown(settings, selectWidth)
  const height = drillDown(settings, selectHeight)
  const convertToWidth = floatNumber => fractionToScreen(width, floatNumber)
  const convertToHeight = floatNumber => fractionToScreen(height, floatNumber)

  return (
<Grid container direction="column" wrap="nowrap">
  <Grid container direction="row" wrap="nowrap">
    <SimpleInput label="Image Width" value={drillDown(settings, selectWidth)} onChange={handleChange({selection: selectWidth})} />
    <SimpleInput label="Image Height" value={drillDown(settings, selectHeight)} onChange={handleChange({selection: selectHeight})} />
    <SimpleInput label="BG Color" value={drillDown(settings, selectBgColor)} onChange={handleChange({selection: selectBgColor, type: 'input'})} type="input" />
    <SimpleInput label="Text Color" value={drillDown(settings, selectTextColor)} onChange={handleChange({selection: selectTextColor, type: 'input'})} type="input" />
  </Grid>
  <Grid container direction="row" wrap="nowrap">
    <SimpleInput label="Role Spacing" value={convertToWidth(drillDown(settings, selectRoleSpacing))} onChange={handleScreenToFraction({selection: selectRoleSpacing, multiplier: width})} />
    <SimpleInput label="Talent Font Size" value={convertToHeight(drillDown(settings, selectTalentFontSize))} onChange={handleScreenToFraction({selection: selectTalentFontSize, multiplier: height})} />
    <SimpleInput label="Heading Font Size" value={convertToHeight(drillDown(settings, selectHeadingFontSize))} onChange={handleScreenToFraction({selection: selectHeadingFontSize, multiplier: height})} />
  </Grid>
  <Grid container direction="row" wrap="nowrap">
    <SimpleInput label="Border Vertical Pad" value={convertToHeight(drillDown(settings, selectBorderVerticalPad))} onChange={handleScreenToFraction({selection: selectBorderVerticalPad, multiplier: height})} />
    <SimpleInput label="Heading Vertical Pad" value={convertToHeight(drillDown(settings, selectHeadingVerticalPad))} onChange={handleScreenToFraction({selection: selectHeadingVerticalPad, multiplier: height})} />
    <SimpleInput label="Section Vertical Pad" value={convertToHeight(drillDown(settings, selectSectionVerticalPad))} onChange={handleScreenToFraction({selection: selectSectionVerticalPad, multiplier: height})} />
    <SimpleInput label="Role Vertical Pad" value={convertToHeight(drillDown(settings, selectRoleVerticalPad))} onChange={handleScreenToFraction({selection: selectRoleVerticalPad, multiplier: height})} />
  </Grid>
</Grid>
  )
}

export default PreviewSettings
