import React from 'react'
import { makeStyles, ListItem, ListItemText, Typography } from '@material-ui/core'
// import { Edit, FileCopy } from '@material-ui/icons'
import clsx from 'clsx'
import { drillDown } from 'deepdown'

const selectCharacterAkaOriginalValue = 'AKAs.0.original.value'.split('.')

const useStyles = makeStyles(theme => ({
  CharacterItemSelected: {
    backgroundColor: theme.palette.secondary.light,
  },
  CharacterItem: {

  },
  CharacterItemActions: {
    textAlign: 'right',
    flex: 1,
  },
}))

const CharacterSearchResultItem = ({
  character,
  onClickSelect,
  isSelected,
}) => {
  const classes = useStyles()

  return (
    <ListItem
      button
      onClick={onClickSelect}
      classes={{
        root: clsx(classes.CharacterItem, isSelected && classes.CharacterItemSelected)
      }}
    >
      <ListItemText
        primary={( <Typography>{drillDown(character, selectCharacterAkaOriginalValue)}</Typography> )}
      />
    </ListItem>
  )
}

export default CharacterSearchResultItem
