
import {
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core'

import Filler from '../Filler'

import AtomImportHeader from './AtomImportHeader'
import FranchiseCharacterHeader from './FranchiseCharacterHeader'
import StandardHeaderButtons from './StandardHeaderButtons'

const CharacterViewHeader = ({
  characterCredits,
  filter,
  showFranchise,
  showAtomImport,
  onClickShowAtomImport,
  onClickCancelAtomImport,
  onClickImportFromAtom,
  atomImportDisabled,
  onClickShowFranchise,
  onClickCancelAddCharacter,
  onClickAddNewCharacter,
  onChangeFilterQuery,
}) => {
  const filterMatchesFranchiseAka = false
  const addCharacterDisabled = !filter.query || filter.query.length===0 || filterMatchesFranchiseAka
  return (
<Grid container direction="row" wrap="nowrap">
  <Tabs
    value={0}
    indicatorColor="primary"
    textColor="primary"
  >
    <Tab label="Characters" />
  </Tabs>
  <Filler />
  {showAtomImport
  ? (<AtomImportHeader {...{
    atomImportDisabled,
    onClickImportFromAtom,
    onClickCancelAtomImport,
  }} />)
  : showFranchise
    ? (<FranchiseCharacterHeader {...{
      filter,
      onChangeFilterQuery,
      addCharacterDisabled,
      onClickAddNewCharacter,
      onClickCancelAddCharacter,
    }} />)
    : (<StandardHeaderButtons {...{
      characterCredits,
      onClickShowAtomImport,
      onClickShowFranchise,
    }} />)
  }
</Grid>
  )
}

export default CharacterViewHeader
