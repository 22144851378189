import { TextField, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  BillingInputInput: {
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
  },
}))

const ImportingBilling = ({
  credit,
  classes,
}) => {
  const styles = useStyles()

  const value = credit.billingOrder || ''

  return (
    <TextField
      classes={classes}
      fullWidth
      type="number"
      variant="standard"
      margin={"dense"}
      inputProps={{
        readOnly: true,
        disabled: true,
      }}
      InputProps={{
        classes: {
          input: clsx(styles.BillingInputInput),
        }
      }}
      value={value}
      label="Billing"
    />
  )
}

export default ImportingBilling
