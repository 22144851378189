import React, { useEffect, useState, useCallback } from 'react'
import './App.css'

import {
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'

import { red } from '@material-ui/core/colors'

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom"

import { useDebounce } from './lib/hooks'

import config from './config'

import Dashboard from './pages/Dashboard'
import Detail from './pages/Detail'
import NoMatch from './pages/NoMatch'
import CharacterEditor from './pages/CharacterEditor'
import TalentEditor from './pages/TalentEditor'

import Auth from '@aws-amplify/auth'
import Amplify, { Hub } from '@aws-amplify/core'
import { fetchFonts, loadFonts, } from './services/google-fonts'

import * as localization from './services/localization'

import { regularDubsheet, } from './lib/parsers'
import { serial } from './lib/async-utils'

import {
  reduceCharactersBySeries,
  reducerLocalizationsIntoTitles,
} from './lib/models'

import { models } from 'dubcard'

// TODO: fetch this from LMT
import LMTBcp47ToDescription from './lib/languages.json'
import { drillDown, indexByKey, unwindByKey } from 'deepdown'

const {
  reducerTitles,
  reducerTalents,
  reducerCharacters,
  reduceCharactersByMpm,
} = models

const theme = createMuiTheme({
  palette: {
    type: config.app.prefersDarkMode ? 'dark' : 'light',
    // primary: blue,
    // secondary: pink,
    primary: {
      light: '#6cfbff',
      main: '#06c8fe',
      dark: '#0097cb',
      contrastText: '#000000',
      // hbomax blue is too bright for white text
      // light: '#63e2ff',
      // main: '#00b0e0',
      // dark: '#0080ae',
      // contrastText: '#ffffff',
    },
    secondary: {
      light: '#ff61ff',
      main: '#ff00e5',
      dark: '#c700b2',
      contrastText: '#000000',
    },
    error: red,
  },
})

window.theme = theme
console.log('theme', window.theme)

const authHubListener = (setUser) => async (authData) => {
  console.log(`--- amplify hub handle [${authData.payload.event}] event`, authData)
  switch (authData.payload.event) {
    case 'configured':
    case 'signOut':
      try {
        const authUser = await Auth.currentAuthenticatedUser()
        setUser(authUser)
      } catch (error) {
        console.log('handleHubEvent currentAuthicatedUser caught exception', error.message || JSON.stringify(error))
        setUser(null)
      }
      break
    case 'signIn':
    case 'signUp':
    case 'cognitoHostedUI':
      console.log('setUser', authData.payload.data)
      setUser(authData.payload.data)
      break
    default: {
      console.log(`Amplify Hub Listener not handling event [${authData.payload.event}] containing: ${JSON.stringify(authData)}`)
    }
  }
}

const handleLocalizedTitleSet = ({setTitles, enqueueCharacters, enqueueTalents}) => response => {
  console.log(`App/getLocalizedTitle, response`, response)

  // update global app cache of title details
  const newTitles = response && response.title && response.title.reduce(reducerTitles, {})
  if (Object.keys(newTitles).length > 0) {
    setTitles(previous => ({ ...(previous || {}), ...newTitles, }))
  }

  enqueueCharacters(response.character)
  enqueueTalents(response.talent)

  return response
}

const handleTitleMetadata = ({setTitles}) => response => {
  setTitles(previous => [response].reduce(reducerLocalizationsIntoTitles, previous))
  return response
}

const dequeueLast = (q, setter) => {
  if (!q || q.length === 0) {
    return null
  }
  const lastItem = q[q.length - 1]
  setter(q.slice(0, -1))
  return lastItem
}

const App = ({ debounceTimeMs = 700 }) => {
  const [user, setUser] = useState(null)
  const [fonts, setFonts] = useState([])
  const [formats, setFormats] = useState([])
  const [languageCodes, setLanguageCodes] = useState([])
  const [deliverySettings, setDeliverySettings] = useState([])
  const [userEmailById, setUserEmailById] = useState({})
  const [titles, setTitles] = useState({})
  const [talents, setTalents] = useState(null)
  const [talentQueue, setTalentQueue] = useState([])
  const [characters, setCharacters] = useState(null)
  const [charactersByMpm, setCharactersByMpm] = useState({})
  const [charactersBySeries, setCharactersBySeries] = useState({})
  const [characterQueue, setCharacterQueue] = useState([])
  const [searchState, setSearchState] = useState({ term: '', results: [], fetching: false, error: null })
  // console.log('--- rendering App component')

  // -------------------------- //
  const [importedXlsx, setImportedXlsx] = useState({})
  const [showInput, setShowInput] = useState(false)
  const [filterTerm, setFilterTerm] = useState('')
  const debouncedTerm = useDebounce(filterTerm, debounceTimeMs)

  // first in
  const enqueueTalents = useCallback(newTalents => {
    setTalentQueue(previous => [newTalents, ...previous])
  }, [setTalentQueue])

  // consume talent queue
  useEffect(() => {
    // first out
    const ts = dequeueLast(talentQueue, setTalentQueue)
    if (!ts) {
      return
    }
    const talentArray = Array.isArray(ts) ? ts : [ts]
    setTalents({ ...(talents || {}), ...(talentArray.reduce(reducerTalents, {})) })
  }, [talentQueue, setTalentQueue, talents, setTalents])

  // first in
  const enqueueCharacters = useCallback(newCharacters => {
    setCharacterQueue(previous => [newCharacters, ...previous])
  }, [setCharacterQueue])

  // consume character queue
  useEffect(() => {
    // first out
    const cs = dequeueLast(characterQueue, setCharacterQueue)
    if (!cs) {
      return
    }
    const chars = Array.isArray(cs) ? cs : [cs]
    setCharacters({ ...(characters || {}), ...(chars.reduce(reducerCharacters, {})) })
    setCharactersByMpm(reduceCharactersByMpm(charactersByMpm, chars))
    setCharactersBySeries(reduceCharactersBySeries(charactersBySeries, chars))
  }, [
    titles,
    characterQueue, setCharacterQueue,
    characters, setCharacters,
    charactersByMpm, setCharactersByMpm,
    charactersBySeries, setCharactersBySeries,
  ])

  const onClickExpandInput = useCallback(() => {
    // console.log('SearchInput - onClick')
    setShowInput(true)
  }, [setShowInput])

  const onChangeTerm = useCallback(e => {
    setFilterTerm(e.target.value)
  }, [setFilterTerm])

  // -------------------------- //

  const getLocalizedTitle = useCallback(query => {
    console.log(`App/getLocalizedTitle, query`, query)
    return localization.getTitleMetadata(query)
      .then(handleLocalizedTitleSet({setTitles, enqueueCharacters, enqueueTalents}))
  }, [setTitles, enqueueCharacters, enqueueTalents])

  const setSearchTerm = useCallback((term) => {
    console.log('--- App - received searchTerm', term)

    if (searchState.term === term) {
      // console.log('--- App/setSearchTerm - term remained the same', term)
      return
    }

    if (!term || term.length === 0) {
      console.log('--- App/setSearchTerm - term was empty, clearing results', term)
      setSearchState({ term, fetching: false, results: [], error: null })
      return
    }

    console.log('--- App/setSearchTerm - term changed', term)
    const searchBefore = { ...searchState, term, fetching: true }
    setSearchState(searchBefore)
    let cancelled = false
    localization.searchTitles({phrase: term}).then(result => {
      if (cancelled) {
        return
      }
      console.log('--- searchTitles - results', result)
      setTitles({ ...titles, ...(result.title.reduce(reducerTitles, {})) })
      setSearchState({ ...searchBefore, fetching: false, results: result.hits, error: null, })
    }).catch(error => {
      console.error('--- searchTitles - error', error)
      setSearchState({ ...searchBefore, fetching: false, error, })
    })

    return () => {
      cancelled = true
    }
  }, [setSearchState, searchState, titles, setTitles])

  const onClickClearTerm = useCallback(e => {
    console.log('clear term')
    setFilterTerm('')
    setSearchState(previous => ({...previous, term: ''}))
    setShowInput(false)
  }, [setFilterTerm, setSearchState, setShowInput])

  useEffect(() => {
    if (debouncedTerm && filterTerm) {
      setSearchTerm(debouncedTerm)
    }
  }, [setSearchTerm, debouncedTerm, filterTerm])

  const signIn = useCallback(({ username, password }) => {
    return Auth.signIn(username, password).then(response => {
      console.log('Application/signIn, response:', response)
      return response
    })
  }, [])

  const onChangeImportUpload = useCallback(async (e) => {
    console.log('--- App/onChangeImportUpload')
    e.persist()
    const file = e.target.files[0]
    if (!file) {
      // TODO: show failure with a Toast
      console.log('load onChangeImportUpload, falsy file')
      return;
    }

    console.log('App/onChangeImportUpload, file.type', file.type)

    try {
      const data = await regularDubsheet(file)
      console.log('App/onChangeImportUpload, data', data)
      setImportedXlsx({data})
    } catch (error) {
      // TODO: show a pop-up message
      console.error(`error loading file ${file} with error: ${error.message || JSON.stringify(error)}`)
      setImportedXlsx({error})
    }
  }, [setImportedXlsx])

  const cancelImport = useCallback( () => {
    console.log('App/onClickCancelImport')
    setImportedXlsx({}) // clears data attribute
  }, [])

  //  --- call 3 APIs and return responses --- 
  const importCreditsFromXlsx = useCallback( async ({mpm, language, ...behaviors}) => {
    console.log(`App/importCreditsFromXlsx, mpm ${mpm}, language ${language}, behaviors: ${JSON.stringify(behaviors)}`)
    const result = await localization.importCreditsFromXlsx({mpm, language, ...behaviors})

    console.log('App/importCreditsFromXlsx, result:', result)

    handleLocalizedTitleSet({setTitles, enqueueCharacters, enqueueTalents})(result)

    // clear imported data
    setImportedXlsx({})
    return result
  }, [setTitles, enqueueTalents, enqueueCharacters])

  const getCreditsForLocalizedTitle = useCallback(input => {
    return localization.getCreditsForLocalizedTitle(input).then(localizedTitle => {
      // no need to handle when title is not already in memory
      setTitles(previous => {
        return (previous[localizedTitle.mpm])
          ? [localizedTitle].reduce(reducerLocalizationsIntoTitles, previous)
          : previous
      })
    })
  }, [setTitles])

  const fetchOriginalTitles = useCallback(async (titles, mpms) => {
    const commands = mpms.filter(mpm => titles[mpm]).map(mpm => {
      // title should already be in app,
      const title = titles[mpm]
      const language = (title && drillDown(title, ['original', 'language']))
      return () => localization.getCreditsForLocalizedTitle({mpm, language})
    })
    const localizedTitles = await serial(commands)
    if (localizedTitles.length > 0) {
      setTitles(localizedTitles.reduce(reducerLocalizationsIntoTitles, titles))
    }
    return localizedTitles
  }, [setTitles])

  const importCharactersFromAtom = useCallback(async ({mpm, behaviors}) => {

    return localization.importCharactersFromAtom({mpm, behaviors}).then(result => {
      console.log('App/importCharactersFromAtom, result', result)
      handleLocalizedTitleSet({setTitles, enqueueCharacters, enqueueTalents})(result)

      const { character } = result
      return character
    })
  }, [setTitles, enqueueCharacters, enqueueTalents])

  const createCharacter = useCallback(model => {
    return localization.createCharacter(model).then(result => {
      console.log('App/createCharacter, result', result)
      const unwoundAkasMpms = unwindByKey(result.AKAs, ['mpm'])
      const akasGroupedByMpm = indexByKey(unwoundAkasMpms, ['mpm'])
      const mpms = Object.keys(akasGroupedByMpm)
      return fetchOriginalTitles(titles, mpms).then(originalTitles => {
        console.log('App/createCharacter/fetchOriginalTitles, results : ', originalTitles)
        enqueueCharacters(result)
        return result
      })
    })
  }, [titles, enqueueCharacters, fetchOriginalTitles])

  const addAkasToCharacter = useCallback(input => {
    const {mpm} = input
    return localization.addAkasToCharacter(input).then(result => {
      console.log('App/addAkasToCharacter, result : ', result)
      enqueueCharacters(result)
      return fetchOriginalTitles(titles, mpm).then(originalTitles => {
        console.log('App/addAkasToCharacter/fetchOriginalTitles, results : ', originalTitles)
        return result
      })
    })
  }, [titles, fetchOriginalTitles, enqueueCharacters])

  const addTitlesToCharacterAka = useCallback(input => {
    const {mpm} = input
    return localization.addTitlesToCharacterAka(input).then(result => {
      console.log('App/addTitlesToCharacterAka, result : ', result)
      enqueueCharacters(result)
      return fetchOriginalTitles(titles, mpm).then(originalTitles => {
        console.log('App/addTitlesToCharacterAka/fetchOriginalTitles, results : ', originalTitles)
        return result
      })
    })
  }, [titles, fetchOriginalTitles, enqueueCharacters])

  const removeTitlesFromCharacterAka = useCallback(input => {
    const {mpm} = input
    return localization.removeTitlesFromCharacterAka(input).then(result => {
      console.log('App/removeTitlesFromCharacterAka, result : ', result)
      enqueueCharacters(result)
      return fetchOriginalTitles(titles, mpm).then(originalTitles => {
        console.log('App/removeTitlesFromCharacterAka/fetchOriginalTitles, results : ', originalTitles)
        return result
      })
    })
  }, [titles, fetchOriginalTitles, enqueueCharacters])

  const updateCharacterAkaOriginal = useCallback(input => {
    return localization.updateCharacterAkaOriginal(input).then(result => {
      console.log('App/updateCharacterAkaOriginal', result)
      enqueueCharacters(result)
      return result
    })
  }, [enqueueCharacters])

  const updateLocalizationToCharacterAka = useCallback(input => {
    return localization.updateLocalizationToCharacterAka(input).then(result => {
      console.log('App/updateLocalizationToCharacterAka', result)
      enqueueCharacters(result)
      return result
    })
  }, [enqueueCharacters])

  const findCharactersByFranchiseId = useCallback(input => {
    return localization.findCharactersByFranchiseId(input).then(result => {
      console.log('App/findCharactersByFranchiseId, result', result)
      enqueueCharacters(result)
      return result
    })
  }, [enqueueCharacters])

  const searchCharacter = useCallback(model => {
    return localization.searchCharacter(model).then(results => {
      // console.log('App/searchCharacter, results', results)
      enqueueCharacters(results)
      return results
    })
  }, [enqueueCharacters])

  const createTalent = useCallback(model => {
    return localization.createTalent(model).then(result => {
      console.log('App/createTalent, result', result)
      enqueueTalents(result)
      return result
    })
  }, [enqueueTalents])

  const addAkasToTalent = useCallback(input => {
    return localization.addAkasToTalent(input).then(result => {
      console.log('App/addAkasToTalent, result', result)
      enqueueTalents(result)
      return result
    })
  }, [enqueueTalents])

  const updateTalentAka = useCallback(input => {
    return localization.updateTalentAka(input).then(result => {
      console.log('App/updateTalentAka, result', result)
      enqueueTalents(result)
      return result
    })
  }, [enqueueTalents])

  const searchTalent = useCallback(model => {
    return localization.searchTalent(model).then(results => {
      // console.log('App/searchTalent, results', results)
      enqueueTalents(results)
      return results
    })
  }, [enqueueTalents])

  const addCreditsToLocalizedTitle = useCallback(input => {
    return localization.addCreditsToLocalizedTitle(input)
      .then(handleTitleMetadata({setTitles}))
  }, [setTitles])

  const removeCreditFromLocalizedTitle = useCallback(input => {
    return localization.removeCreditFromLocalizedTitle(input)
      .then(handleTitleMetadata({setTitles}))
  }, [setTitles])

  const updateTalentForCharacterCredit = useCallback(input => {
    return localization.updateTalentForCharacterCredit(input)
      .then(handleTitleMetadata({setTitles}))
  }, [setTitles])

  const updateBillingForOriginalCharacterCredit = useCallback(input => {
    return localization.updateBillingForOriginalCharacterCredit(input)
      .then(handleTitleMetadata({setTitles}))
  }, [setTitles])

  const updateBillingForLocalizedCredit = useCallback(input => {
    return localization.updateBillingForLocalizedCredit(input)
      .then(handleTitleMetadata({setTitles}))
  }, [setTitles])

  const updateEpisodeOverrideForSeason = useCallback(input => {
    return localization.updateEpisodeOverrideForSeason(input)
      .then(handleTitleMetadata({setTitles}))
  }, [setTitles])

  const removeEpisodeOverrideForSeason = useCallback(input => {
    return localization.removeEpisodeOverrideForSeason(input)
      .then(handleTitleMetadata({setTitles}))
  }, [setTitles])

  const getUserAttributes = useCallback(async input => {
    if (userEmailById[input.userId]) {
      return userEmailById[input.userId]
    }

    return localization.getUserAttributes(input).then(email => {
      setUserEmailById(data => ({
        ...data,
        [input.userId]: email,
      }))

      return email
    })
  }, [userEmailById, setUserEmailById])

  const apiProps = {
    // character
    createCharacter,
    addAkasToCharacter,
    addTitlesToCharacterAka,
    removeTitlesFromCharacterAka,
    updateCharacterAkaOriginal,
    updateLocalizationToCharacterAka,
    findCharactersByFranchiseId,
    searchCharacter,
    getAtomCharacters: localization.getAtomCharacters,
    importCharactersFromAtom,
    // talent
    createTalent,
    addAkasToTalent,
    updateTalentAka,
    searchTalent,
    // title
    importCreditsFromXlsx,
    addCreditsToLocalizedTitle,
    removeCreditFromLocalizedTitle,
    updateTalentForCharacterCredit,
    updateBillingForOriginalCharacterCredit,
    updateBillingForLocalizedCredit,
    updateEpisodeOverrideForSeason,
    removeEpisodeOverrideForSeason,
    getLocalizedTitle,
    getCreditsForLocalizedTitle,
    // getTitlesForTalent: getTitlesForTalentHandler,
    // deliveries
    renderRasterBySvg: localization.renderRasterBySvg,
    createDelivery: localization.createDelivery,
    getDeliveries: localization.getDeliveries,
    getUserAttributes,
  }

  const searchProps = {
    filterTerm,
    //     setFilterTerm,
    // setSearchTerm,
    onChangeTerm,
    onClickClearTerm,
    showInput,
    onClickExpandInput,
    searchState,
  }

  const importProps = {
    onChangeImportUpload,
    importedXlsx,
    cancelImport,
    saveImport: importCreditsFromXlsx,
  }

  const pageProps = {
    LMTBcp47ToDescription,
    titles,
    setTitles,
    talents,
    characters,
    charactersByMpm,
    charactersBySeries,
    signIn,
  }

  useEffect(() => {
    if (!user) {
      return
    }

    localization.listDeliverySettings().then(result => {
      setDeliverySettings(result)
      console.log('App/useEffect/listDeliverySettings - result', result)
    }).catch(error => {
      console.error('App/useEffect/listDeliverySettings - error', error)
    })
  }, [user, setDeliverySettings])

  useEffect(() => {
    if (!user) {
      return
    }
    localization.getLanguageCodes().then(codes => {
      setLanguageCodes(codes)
    })
  }, [user, setLanguageCodes])

  useEffect(() => {
    if (!user) {
      return
    }

    console.log('--- App/useEffect - fetchFonts')
    localization.fetchFormats()
      .then((response) => {
        setFormats(response.data)
      })
  }, [user, setFormats])

  useEffect(() => {
    if (!user) {
      return
    }

    console.log('--- App/useEffect - fetchFonts')
    fetchFonts()
      .then(resp => loadFonts({ fonts: resp.data.fetchFonts }))
      .then((loaded) => {
        setFonts(loaded)
      })
  }, [user, setFonts])

  useEffect(() => {
    console.log('--- App/useEffect - Hub.listen')
    const listener = authHubListener(setUser)
    Hub.listen('auth', listener)

    return () => {
      console.log('--- App/useEffect - Hub.listen DESTROY')
      Hub.remove('auth', listener)
    }
  }, [setUser])

  useEffect(() => {
    console.log('--- App/useEffect - Amplify.configure')
    Amplify.configure(config.Amplify)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/" render={({ location }) => (
            <Dashboard {...{ user, location, ...searchProps, ...pageProps, ...importProps, ...apiProps, }} />
          )} />
          <Route path="/mpm/:mpm" render={({ location }) => (
            <Detail  {...{ location, user, fonts, formats, ...searchProps, ...pageProps, ...importProps, ...apiProps, deliverySettings }} />
          )}/>
          <Route path="/character" render={({ location }) => (
            <CharacterEditor  {...{ location, user, ...searchProps, ...pageProps, ...importProps, ...apiProps, languageCodes }} />
          )} />
          <Route path="/talent" render={({ location }) => (
            <TalentEditor  {...{ location, user, ...searchProps, ...pageProps, ...importProps, ...apiProps, }} />
          )}/>
          <Route render={(props) => <NoMatch  {...{ ...props, user, ...searchProps, ...pageProps, ...importProps, }} />} />
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

export default App;
