import { Grid, makeStyles, Typography, List, } from '@material-ui/core'

import CharacterSearchResultItem from './CharacterSearchResultItem'
import CharacterSearchHeader from './CharacterSearchHeader'
import FullHeight from '../FullHeight'


const useStyles = makeStyles(theme => ({
  expand: {
    flex: 1,
  },
  CharacterSearchResults: {
    padding: theme.spacing(1),
  },
  CharacterSearchResultsList: {
    flex: 1,
    overflow: 'auto',
  },
  CharacterItem: {

  },
  CharacterEditorAddForm: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  CharacterItemActions: {
    textAlign: 'right',
    flex: 1,
  },
}))

const DisplayMessage = ({text}) => {
  return (
<Grid container direction="row" alignItems="center">
  <Typography>{text}</Typography>
</Grid>
  )
}

const CharacterSearchResults = ({
  searchResults,
  selected,
  onClickSelect,
}) => {
  const classes = useStyles()
  const sortedTalent = (searchResults && searchResults.results) || []

  return (
<div className={classes.CharacterSearchResults}>
  {(!searchResults.fetching && sortedTalent.length === 0) && <DisplayMessage text="No search results" />}
  {searchResults.fetching && <DisplayMessage text="Searching..." />}
  {/* {(!searchState.fetching && searchState.term && searchState.term.length > 0 && searchState.results.length === 0) && <DisplayMessage text="No results in search" />} */}
  {(sortedTalent.length > 0) && (
    <List className={classes.CharacterSearchResultsList}>
      {sortedTalent.map(c => (
        <CharacterSearchResultItem
          onClickSelect={onClickSelect(c)}
          character={c}
          isSelected={selected && (selected.id === c.id)}
          key={`/character-editor/search-result/${c.id}`}
        />
      ))}
    </List>
  )}
</div>
  )
}


const CharacterSearch = ({
  searchResults,
  inputValue,
  onClickSelect,
  onClickClearSearch,
  onChangeInputValue,
  onClickAddTalent,
  selected,
}) => {
  return (
  <FullHeight>
    <CharacterSearchHeader {...{
      inputValue,
      onChangeInputValue,
      onClickClearSearch,
      onClickAddTalent,
    }} />
    <CharacterSearchResults {...{
      searchResults,
      selected,
      onClickSelect,
    }} />
  </FullHeight>
  )
}

export default CharacterSearch
