
import {
  Button,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  HeaderButton: {
    margin: theme.spacing(1),
  },
}))

const StandardHeaderButtons = ({
  characterCredits,
  onClickShowAtomImport,
  onClickShowFranchise,
}) => {
  const classes = useStyles()
  return (
<>
  <Button
    disabled={!characterCredits || characterCredits.length !== 0}
    id="import-from-atom"
    variant="contained"
    color="primary"
    onClick={onClickShowAtomImport}
    size="small"
    className={classes.HeaderButton}
  >IMPORT FROM ATOM</Button>

  <Button
    id="add-character"
    variant="contained"
    color="primary"
    onClick={onClickShowFranchise}
    size="small"
    className={classes.HeaderButton}
  >ADD MANUALLY</Button>

</>
  )
}

export default StandardHeaderButtons
