
export const selectUser = ['user']
export const selectUserSession = 'user.session'.split('.')

export const selectUserEmailFromSession = 'attributes.email'.split('.');

// export const selectEditor = 'editor'.split('.')
export const selectFormat = 'format'.split('.')

export const selectLoadedTalent = 'loadedTalent'.split('.')

export const selectTalent = 'talent'.split('.')

export const selectSearchResults = 'search'.split('.')
export const selectSearchFocus = 'search.focused'.split('.')
export const selectSearchTerm = 'search.term'.split('.')

export const selectFonts = 'fonts'.split('.')

// from responses
export const selectApiGetTalent = 'data.getLocalizedTalent.0.localized'.split('.')
export const selectApiUpdateTalent = 'data.updateLocalizedTalent'.split('.')

export const selectBillingFromLocalizedTalent = ['roles', 'creditSequence']
export const selectBillingFromOriginalTalent = ['creditSequence']
export const selectNameFromOriginalTalent = ['talentName']
export const selectCharacterFromOriginalTalent = ['originalCharacterName']
export const selectRoleFromOriginalTalent = ['type']
export const selectOriginalCharacterFromLocalizedRole = ['originalCharacterId']
export const selectRoleFromLocalizedCredit = ['role']
export const selectCharacterFromLocalizedRole = ['roles', 'character']
export const selectNameFromLocalizedRole = ['fullName']
export const selectIsMaskedFromLocalizedRole = ['roles', 'isMasked']
export const selectTitleTalentFromPageData = 'original.roles'.split('.')
export const selectMpmFromTitle = 'mpm'.split('.')

export const selectSeasonOrEpisodeFromTitleMetadata = 'originallyAiredAs'.split('.')
// export const selectTitleFromPageData = 'title.title'.split('.')
export const selectOriginalLanguage = 'title.originalLanguage'.split('.')
export const selectTypeFromPageData = 'title.type'.split('.')
export const selectOriginalTitleFromTitle = 'original.title'.split('.')
export const selectOriginalReleaseYearFromPageData = 'title.originalReleaseYear'.split('.')
