import { drillDown, } from 'deepdown';
import {
  selectOriginalReleaseYearFromPageData,
} from '../lib/selections';

const findPreferredRendition = preferredRendition => r => r && r.renditionName === preferredRendition

export const findCountry = (countries, countryOfInterest) => {
  const country = countries.find(c => c.name === countryOfInterest)
  return country || countries[0]
}

export const displayName = titleMetadata => {
  return titleMetadata && drillDown(titleMetadata, ['original', 'title'])
}

export const releaseYear = pageData => {
  return drillDown(pageData, selectOriginalReleaseYearFromPageData)
}

// poster rendition
export const poster = (titlePoster, preferredRendition) => {
  const preferred = ((titlePoster && titlePoster.renditions) || []).filter(findPreferredRendition(preferredRendition))
  if (preferred.length > 0) {
    return preferred[0]
  }

  // const fallback = (drillDown(title, selectTitlePosterRenditions) || []).filter(findValidUrl)
  // if (fallback.length > 0) {
  //   return fallback[0]
  // }

  return null;
  // return drillDown(title, 'poster.renditions.0.url'.split('.'));
};

// synopsis
export const synopsis = title => {
  const indexOfCountry = countryIndex(title);
  return drillDown(title,`country.${indexOfCountry}.language.0.longSynopsis`.split('.')) || '';
};

// shortSynopsis
export const shortSynopsis = title => {
  const indexOfCountry = countryIndex(title);
  return drillDown(title,`country.${indexOfCountry}.language.0.shortSynopsis`.split('.')) || '';
};

// genres
export const genre = title => {
    const indexOfCountry = countryIndex(title);
    return (drillDown(title, `country.${indexOfCountry}.language.0.genre`.split('.')) || []).join(', ')
};

// ratings
export const ratings = title => {
  const indexOfCountry = countryIndex(title);
  return drillDown(title, `country.${indexOfCountry}.ratings.0.value`.split('.')) || '';
};

// country index
export const countryIndex = preferredCountry => title => {
    return (!title.country
        ? -1
        : title.country.findIndex(i => i.name === preferredCountry)) === -1
        ? 0
        : title.country.findIndex(i => i.name === preferredCountry);
};

// talent
export const talent = (talent, credit, display) => {
  if (!display) {
    display = credit;
  }

  const talentList = (talent || [])
    .filter(property => property.role === credit)
    .map(item => item.label);

  if (talentList.length > 1) {
    display = display + 's';
  }

  return {display : display, talent: talentList.join(', ')}
}
