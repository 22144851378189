import {
  Button,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  HeaderButton: {
    margin: theme.spacing(1),
  },
}))

const AtomImportHeader = ({
  atomImportDisabled,
  onClickImportFromAtom,
  onClickCancelAtomImport,
}) => {
  const classes = useStyles()
  return (
<>
  <Button
    id="add-new-character"
    variant="contained"
    color="secondary"
    disabled={atomImportDisabled}
    onClick={onClickImportFromAtom}
    size="small"
    className={classes.HeaderButton}
  >IMPORT</Button>
  <Button
    id="cancel-add-character"
    variant="text"
    onClick={onClickCancelAtomImport}
    size="small"
    className={classes.HeaderButton}
  >CANCEL</Button>
</>
  )
}

export default AtomImportHeader
