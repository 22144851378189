import React, { useState, useCallback, useRef, useEffect } from 'react'
import { drillDown, } from 'deepdown'
import { TextField, makeStyles, IconButton } from '@material-ui/core'
import { Clear, LockOutlined, LockOpen } from '@material-ui/icons'

import { roles } from 'dubcard'

const {castRoles, RoleTypes} = roles

const useStyles = makeStyles((/*theme*/) => ({
  CharacterFieldRoot: {
    '& label': {
      whiteSpace: 'nowrap',
      // padding: 0.5*theme.spacing(1),
    },
  },
}))

const LocalizedCharacter = ({
  original,
  localized,
  characters,
  readOnly,
  lang,
  updateLocalizationToCharacterAka,
}) => {
  const classes = useStyles()

  const role = (localized && localized.role) || (original ? RoleTypes.DUBBING_VOICE : '')
  const characterId = (localized && drillDown(localized, ['character', 'id'])) || (original && drillDown(original, ['character', 'id']))
  const characterAkaId = characterId && ((localized && drillDown(localized, ['character', 'aka'])) || (original && drillDown(original, ['character', 'aka'])))
  const character = characterId && characters && characters[characterId]
  const characterAka = character && character.AKAs && character.AKAs.find(aka => aka.id === characterAkaId)
  const localizedAka = characterAka && characterAka.localizations && characterAka.localizations.find(loc => loc.language === lang)
  const localizedName = localizedAka && localizedAka.value

  const hasData = !!localizedName
  const [editedName, setEditedName] = useState(localizedName)
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const [needsFocus, setNeedsFocus] = useState(false)

  useEffect(() => {
    // console.log('useEffect/needsFocus', needsFocus)
    if (needsFocus) {
      inputRef.current.focus()
    }
  }, [needsFocus, inputRef])

  const onBlur = useCallback(() => {
    // console.log('onBlur')
    if (editedName===localizedName) {
      setEditing(false)
    }
    setNeedsFocus(false)
  }, [setEditing, editedName, localizedName])

  const onFocus = useCallback(() => {
    // console.log('onFocus')
    setEditing(true)
  }, [setEditing])

  const onClickEnableEdit = useCallback(() => {
    // console.log('onClickEnableEdit')
    if (!editedName && localizedName) {
      setEditedName(localizedName)
    }
    setNeedsFocus(true)
    setEditing(true)
  }, [editedName, setEditedName, localizedName])

  const onClickCancelEdit = useCallback(() => {
    // console.log('onClickCancelEdit')
    setEditing(false)
    setEditedName(localizedName)
  }, [setEditedName, localizedName])

  const onChangeLocalizedName = useCallback(e => {
    // console.log('onChangeLocalizedName', e.target.value)
    setEditedName(e.target.value)
  }, [setEditedName])

  const onClickSaveEdit = useCallback(() => {
    const localization = {language: lang, value: editedName}
    const input = {id: characterId, akaId: characterAkaId, localization}
    return updateLocalizationToCharacterAka(input).then(result => {
      console.log('LocalizedCharacter/onClickSaveEdit/updateLocalizationToCharacterAka, result : ', result)
      setEditing(false)
    }).catch(error => {
      console.error('LocalizedCharacter/onClickSaveEdit/updateLocalizationToCharacterAka, error : ', error)
    })
  }, [updateLocalizationToCharacterAka, setEditing, editedName, characterId, characterAkaId, lang])

  return (
  <>
    {(!original)
    ? (
      <TextField
        fullWidth
        label={'Role'}
        value={role || ''}
        classes={{ root: classes.CharacterFieldRoot }}
        disabled={true}
        inputProps={{ readOnly: true, disabled: true }}
      />
    )
    : (
    <>
      <TextField
        fullWidth
        disabled={(!needsFocus && !editing && hasData)}
        inputRef={inputRef}
        onFocus={onFocus}
        onBlur={onBlur}
        label={(!castRoles.includes(role)) ? 'Role' : 'Localized Character'}
        value={editing ? (editedName || '') : (localizedName || '')}
        onChange={onChangeLocalizedName}
        classes={{ root: classes.CharacterFieldRoot }}
        InputProps={{
          startAdornment: (!readOnly && (
            editing ? (
              <IconButton onClick={onClickSaveEdit} size="small" color="secondary" disabled={editedName === localizedName}>
                <LockOpen />
              </IconButton>
            ) : (
            <IconButton onClick={onClickEnableEdit} size="small" disabled={!hasData}>
              <LockOutlined />
            </IconButton>
            )
          )),
          endAdornment: (!readOnly && (
            <IconButton onClick={onClickCancelEdit} size="small" disabled={!editing && (editedName === localizedName)}>
              <Clear />
            </IconButton>
          )),
        }}
        inputProps={(readOnly)
          ? { readOnly: true, disabled: true }
          : {}
        }
      />
    </>
    )
    }
  </>
  )
}

export default LocalizedCharacter
