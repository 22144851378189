import { makeStyles } from "@material-ui/core"
import ImportingBehavior from "./ImportingBehavior"
import ImportingName from "./ImportingName"
import ImportingExistingCharacter from "./ImportingExistingCharacter"

import clsx from 'clsx'

import {bulk} from 'dubcard'

const {BehaviorMode} = bulk

const useStylesImportingCharacter = makeStyles(theme => ({
  ImportingCharacter: {
    padding: `0 ${theme.spacing(1)}px`,
    gap: theme.spacing(1),
  },
  ImportingBehavior: {
    minWidth: 160,
    maxWidth: 160,
    width: 160,
  },
}))


const ImportingCharacter = ({
  credit,
  characters,
  classesBehavior,
  unwoundFranchiseCharacterAKAs,
  onChangeCharacter,
  onChangeBehaviorCharacter,
}) => {
  const classes = useStylesImportingCharacter()

  const behaviorClass = clsx(
    (credit.ignore)
      ? classesBehavior.XlsxBehaviorIgnore
      : (credit.character.mode === BehaviorMode.USE_EXISTING)
        ? (credit.character.entity && credit.character.entity.id)
          ? classesBehavior.XlsxBehaviorUseExisting
          : classesBehavior.XlsxBehaviorCreateNew
        : classesBehavior.XlsxBehaviorCreateNew
    )

  return (
<>
  <ImportingName label="Importing Character" value={credit.xlsxRole.originalCharacterName} />
  <ImportingBehavior mode={credit.character.mode} className={clsx(classes.ImportingBehavior, behaviorClass)} onChangeBehavior={onChangeBehaviorCharacter} />
  <ImportingExistingCharacter {...{credit, characters, behaviorClass, unwoundFranchiseCharacterAKAs, onChangeCharacter}} />
</>
  )
}

export default ImportingCharacter
