import { Button, CircularProgress, Grid, makeStyles, TextField, Typography } from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"
import {drillDown} from 'deepdown'

import Filler from "../Filler"
import DropDown from '../DropDown'
import {interpolate} from 'dubcard'

const useStyles = makeStyles(theme => ({
  AddDeliveryForm: {
    padding: theme.spacing(1),
  },
  AddDeliveryFormHeader: {
    margin: `${theme.spacing(2)}px 0`,
  },
  expand: {
    flex: 1,
  },
  PlatformLabel: {
    padding: theme.spacing(1),
    width: 120,
  },
  FormFieldLabel: {
    padding: theme.spacing(1),
    width: 120,
  },
  DeliverySettings: {
    margin: `0 0 0 ${theme.spacing(2)}px`,
  },
  CompactField: {
    minWidth: 120,
    maxWidth: 120,
    // flex: 1,
    margin: `0 ${theme.spacing(1)}px`,
  },
}))

const FormField = ({
  disabled,
  label,
  value,
}) => {
  const classes = useStyles()
  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <Typography variant="h6" className={classes.FormFieldLabel}>{label}</Typography>
      <TextField
        fullWidth
        disabled={disabled}
        value={value || ''}
      />
    </Grid>
  )
}

const DeliverySettings = ({
  settings,
  title: titleMetadata,
  language,
  pages,
}) => {
  const classes = useStyles()
  const formatOptions = [{value: settings.format, display: settings.format}]

  const title = drillDown(titleMetadata, ['original', 'title']) || ''

  const fileParams = {
    index: '#',
    page: '#', //index + 1,
    mpm: titleMetadata.mpm,
    bcp47: language,
    totalPages: pages.length,
    width: settings.width,
    height: settings.height,
    fileExtension: settings.format.toLowerCase(),
    title: title.replace(/ /g, ''),
    titleWithSpaces: title,
  }

  return (
    <div className={classes.DeliverySettings}>
      <FormField label="Bucket" value={settings && settings.bucket} disabled={true}/>
      <FormField label="Prefix" value={settings && settings.prefix} disabled={true}/>
      <FormField label="Files" disabled={true}
        value={(settings && settings.filesTemplate && interpolate(settings.filesTemplate, fileParams)) || ''}
      />
      <Grid container direction="row" wrap="nowrap" alignItems="flex-end">
        <TextField label="Width" value={settings.width} disabled={true} classes={{root: classes.CompactField}} />
        <TextField label="Height" value={settings.height} disabled={true} classes={{root: classes.CompactField}} />
        <TextField label="AR" value={(settings.width / settings.height).toFixed(2)} disabled={true} classes={{root: classes.CompactField}} />
        <DropDown label="Format" disabled={true} options={formatOptions} value={settings.format} className={classes.expand} />
      </Grid>
    </div>
  )
}

const AddDeliveryForm = ({
  title,
  language,
  pages,
  setShowAddDelivery,
  createDelivery,
  deliverySettings,
}) => {
  const classes = useStyles()
  const [formData, setFormData] = useState({mpm: (title && title.mpm), language})
  const [submitStatus, setSubmitStatus] = useState({fetching: false, formData: null, error: null})

  const settings = (deliverySettings || []).find(x => (x.platform === formData.platform))

  const platformOptions = [
    ...(deliverySettings || []).map(settings => ({
      value: settings.platform,
      display: settings.platform,
    }))
  ]

  const [selectedPlatform, setSelectedPlatform] = useState(platformOptions.length === 1 ? platformOptions[0].value : '')

  const onClickCancel = useCallback(() => {
    setShowAddDelivery(false)
  }, [setShowAddDelivery])

  const onClickSubmit = useCallback(() => {
    setSubmitStatus(sub => ({...sub, fetching: true, formData}))
    createDelivery(formData).then(result => {
      console.log('AddDeliveryForm/onClickSubmit/createDelivery - result', JSON.stringify(result))
      setSubmitStatus({}) // reset form
      setShowAddDelivery(false)  // show list view
    }).catch(error => {
      console.error('AddDeliveryForm/onClickSubmit/createDelivery - error', JSON.stringify(error))
      setSubmitStatus(sub => ({...sub, fetching: false, error}))
    })
  }, [setShowAddDelivery, createDelivery, formData])

  const onChangePlatform = useCallback((e) => {
    const platform = e.target.value
    setSelectedPlatform(platform)
  }, [setSelectedPlatform])

  useEffect(() => {
    if (selectedPlatform) {
      setFormData(data => ({...data, platform: selectedPlatform}))
    }
  }, [selectedPlatform, setFormData])

  return (
    <div className={classes.AddDeliveryForm}>
      <Grid container direction="row" wrap="nowrap" className={classes.AddDeliveryFormHeader}>
        <Typography variant="h4">New Delivery</Typography>
        <Filler />
        {submitStatus.fetching && <CircularProgress color="secondary" />}
        <Button variant="text" onClick={onClickCancel}>CANCEL</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickSubmit}
          disabled={submitStatus.fetching || (selectedPlatform === '')}
        >SUBMIT</Button>
      </Grid>
      <Grid container direction="row" wrap="nowrap" alignItems="flex-end">
        <Typography variant="h5" className={classes.PlatformLabel}>Platform</Typography>
        <DropDown value={selectedPlatform} options={platformOptions} onChange={onChangePlatform} className={classes.expand} />
      </Grid>
      {settings && <DeliverySettings {...{settings, title, language, pages}} />}
    </div>
  )
}

export default AddDeliveryForm
