
import {
  fade,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core'

import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'

import Billing from '../editor/Billing'

import clsx from 'clsx'
import DropDown from '../DropDown'

import { bulk } from 'dubcard'
const { BehaviorMode } = bulk


const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
  AtomImportContent: {
    height: '100%',
    overflow: 'auto',
  },
  AtomCharacterListItem: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  AtomCharacterListItemCreateNew: {
    backgroundColor: fade(theme.palette.secondary.main, 0.25),
  },
  AtomCharacterListItemUseExisting: {
    backgroundColor: fade(theme.palette.primary.main, 0.25),
  },
  AtomCharacterListItemIgnore: {
    backgroundColor: fade(theme.palette.grey[900], 0.25),
  },
  CharacterName: {
    flex: 1,
    margin: `0 ${theme.spacing(2)}px`,
  },
  Billing: {
    maxWidth: 36,
    minWidth: 36,
  },
  BehaviorMode: {
    maxWidth: 160,
    minWidth: 160,
    // width: 160,
    paddingRight: theme.spacing(1),
  },
  BehaviorCharacter: {
    flex: 1,
  },
}))


const CharacterName = ({
  data,
  readOnly=true,
}) => {
  const classes = useStyles()
  return (
<TextField {...{
  fullWidth: true,
  label: 'Importing Name',
  value: data.characterName,
  classes: {root: classes.CharacterName},
  inputProps: !readOnly ? {} : {
    readOnly: (true),
    disabled: (true),
  },
}} />
  )
}

const IgnoreItem = ({
  onClickIgnoreItem,
  ignore=false,
}) => {
  return (
<Tooltip title="toggle ignore when importing">  
  <IconButton
    onClick={onClickIgnoreItem}
    color={ignore ? "secondary" : "default"}
  >
    {ignore ? <VisibilityOff /> : <Visibility />}
  </IconButton>
</Tooltip>
  )
}

const Behavior = ({
  behavior,
  franchiseCharacters,
  onChangeMode,
  onChangeCharacter,
}) => {
  const classes = useStyles()
  const optionsMode = Object.keys(BehaviorMode).map(k => ({
    value: BehaviorMode[k],
    display: BehaviorMode[k],
  }))
  const optionsCharacter = [
    {value: '', display: ""},
    ...(franchiseCharacters || []).map(c => {
      const indexAka = (
        behavior && behavior.character && (c.id === behavior.character.id)
        && (
          behavior.character.AKAs && behavior.character.AKAs.id
          && c.AKAs.findIndex(cAka => cAka.id === behavior.character.AKAs.id)
        )
      ) || -1

      return {
        value: c.id,
        display: (indexAka !== -1)
          ? c.AKAs[indexAka].original.value
          : c.AKAs[0].original.value
      }
    }),
  ]

  return (
<>
  <DropDown
    className={classes.BehaviorMode}
    label="Import Behavior"
    value={behavior ? behavior.mode : ''}
    options={optionsMode}
    onChange={onChangeMode}
  />
  <DropDown
    disabled={(!behavior || behavior.mode !== BehaviorMode.USE_EXISTING)}
    className={classes.BehaviorCharacter}
    label={!behavior ? "Loading" : (behavior.mode !== BehaviorMode.USE_EXISTING) ? "Not Using" : (behavior.character && behavior.character.id) ? "Using Character" : "Choose"}
    value={(behavior && (behavior.mode === BehaviorMode.USE_EXISTING) && behavior.character) ? behavior.character.id : ''}
    options={(behavior && behavior.mode) !== BehaviorMode.USE_EXISTING ? [] : optionsCharacter}
    onChange={onChangeCharacter}
  />
</>
  )
}

const AtomCharacterListItem = ({
  data,
  franchiseCharacters,
  behavior,
  onClickIgnoreItem,
  onChangeMode,
  onChangeCharacter,
}) => {
  const classes = useStyles()

  return (
<Paper className={clsx(
  classes.AtomCharacterListItem,
  !behavior 
    ? null
    : (behavior.ignore)
      ? classes.AtomCharacterListItemIgnore
      : (behavior.mode === BehaviorMode.USE_EXISTING)
        ? (behavior.character && behavior.character.id)
          ? classes.AtomCharacterListItemUseExisting
          : classes.AtomCharacterListItemCreateNew
        : classes.AtomCharacterListItemCreateNew
  )}>
  <Grid container direction="row" alignItems="center" wrap="nowrap" className={classes.fullHeight}>
    <div className={classes.Billing}><Billing {...{value: data.billingOrder, readOnly: true}} /></div>
    <CharacterName {...{data}} />
    <Behavior {...{
      behavior,
      franchiseCharacters,
      onChangeMode,
      onChangeCharacter,
    }} />
    <IgnoreItem {...{
      onClickIgnoreItem,
      ignore: behavior && behavior.ignore,
    }} />
  </Grid>
</Paper>
  )
}

export default AtomCharacterListItem
