import XLSX from 'xlsx'
import {roles} from 'dubcard'

const {RoleTypes} = roles

const roleNames = Object.keys(RoleTypes).map(k => RoleTypes[k])

const parseSheets = workbook => (accum, sheetName) => {
  const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })
  if (roa.length) {
    return {
      ...accum,
      [sheetName]: roa,
    }
  }
  return accum
}

// const regexCrew = /Crew/
const regexRole = /Role/
const regexVoicesMain = /Voices - Main/
const regexLocalizedActor = /Localized Actor/

const findCrewBegin = rows => {
  // console.log('findCrewBegin, rows', rows)
  const indexRole = rows.findIndex(row => regexRole.test(row[0]))
  if (indexRole > -1) {
    return indexRole + 1
  }
  const indexByRole = rows.findIndex(row => roleNames.includes(row[0]))
  return indexByRole
}

const findCrewEnd = rows => {
  const indexByNextHeading = rows.findIndex(row => regexVoicesMain.test(row[0]))
  if (indexByNextHeading > 0) {
    return indexByNextHeading - 1
  }

  const indexByNextColumnHeader = rows.findIndex(row => regexLocalizedActor.test(row[0]))
  if (indexByNextColumnHeader > 0) {
    return indexByNextColumnHeader - 1
  }

  return -1
}

export const sheetToCreditsModel = rows => {
  const titleKeyRow = rows.find(row => row[0] === 'mpm')
  const languageRow = rows.find(row => row[0] === 'language')

  const crewRowIndexBegin = findCrewBegin(rows)
  const crewRowIndexEnd = findCrewEnd(rows)
  const crewRows = (crewRowIndexBegin > -1 && crewRowIndexEnd > crewRowIndexBegin)
    ? rows.slice(crewRowIndexBegin, crewRowIndexEnd + 1)
    : []
  const crew = crewRows.filter(c => c[0] && c[1])
  // console.log('crewRowIndexBegin', crewRowIndexBegin)
  const charactersRowIndex = rows.findIndex(row => row[0] === 'Localized Actor')
  const voicesOtherRowIndex = rows.findIndex(row => row[0] === 'Voices - Other')
  const characters = charactersRowIndex === -1 ? [] : rows.slice(1 + charactersRowIndex, voicesOtherRowIndex !== -1 ? voicesOtherRowIndex : undefined).filter(row => {
    return (row.length > 0) && (row[0] || row[1])
  })
  const voicesOther = voicesOtherRowIndex === -1 ? [] : rows.slice(1 + voicesOtherRowIndex).filter(row => {
    return (row.length > 0) && row[0]
  })

  return {
    ...((!titleKeyRow || !titleKeyRow[1]) ? {} : {[titleKeyRow[0]]: titleKeyRow[1].toString()}),
    language: languageRow && languageRow[1],
    roles: [
      ...(crew.map(c => ({
        type: c[0],
        talentName: c[1],
        billing: c[2],
      }))),
      ...(characters.map(c => ({
        type: RoleTypes.DUBBING_VOICE,
        talentName: c[0],
        localizedCharacterName: c[1],
        billing: c[2],
        originalCharacterName: c[3],
        isCharacter: true,
        // originalCharacterId: c[4],
        // showRole: c[5],
      }))),
      ...(voicesOther.map(c => ({
        type: RoleTypes.DUBBING_VOICE,
        talentName: c[0],
        billing: c[2],
        // localizedCharacterName: c[1],
        // originalCharacterName: c[3],
        // originalCharacterId: c[4],
        // showRole: c[5],
      })))
    ]
  }
}

const onLoadedSheet = resolve => e => {
  console.log('loaded')
  // const data = new Uint8Array(e.target.result)
  const workbook = XLSX.read(e.target.result, { type: 'array' })
  const sheets = workbook.SheetNames.reduce(parseSheets(workbook), {})
  console.log('onLoadedSheet', sheets)
  resolve(sheetToCreditsModel(sheets[workbook.SheetNames[0]]))
};

const onLoadedError = reject => (e) => {
  console.log('onLoadedError', e.message)
  reject(e)
}

export const regularDubsheet = (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = onLoadedSheet(resolve)
    reader.onerror = onLoadedError(reject)
    reader.readAsArrayBuffer(file);
  })
}

export const mergeLocalized = (saved, loaded) => {
  const localizedIndex = saved.localized.findIndex(ldoc => ldoc.language === loaded.language)
  return {
    ...saved,
    localized: (localizedIndex === -1)
      ? [
          ...(saved.localized),
          loaded,
        ]
      : [
          ...(saved.localized.slice(0, localizedIndex)),
          loaded,
          ...(saved.localized.slice(localizedIndex + 1)),
        ]
  }
}
