import React, { useCallback, useEffect, useState } from 'react'
import { ReactComponent as MissingAvatar } from '../../icons/bugs-bts_svgo.svg'
import { Avatar, Grid,   makeStyles, } from '@material-ui/core'
import { drillDown } from 'deepdown'
import { selectCharacterFromLocalizedRole, } from '../../lib/selections'
import Billing from './Billing'
import {equivalentBilling} from '../../lib/models'

const useStyles = makeStyles(theme => ({
  icon: {
    height: 24,
    fill: theme.palette.grey[500],
  },
  expand: {
    flex: 1,
  },
}))

const validateLocalized = (localized) => (localized.fullName && drillDown(localized, selectCharacterFromLocalizedRole))

const TalentAvatar = ({
  lang,
  title,
  readOnly,
  original,
  localized,
  localizedOverride,
  updateBillingForLocalizedCredit,
}) => {
  const classes = useStyles()
  const billingOriginal = original && original.billingOrder
  const billingLocalized = localized && localized.billingOrder
  const [billing, setBilling] = useState(billingLocalized)

  const forRendering = (localizedOverride && validateLocalized(localizedOverride)) || (localized && validateLocalized(localized))
  const colorDefault = forRendering ? classes.exists : classes.missing

  const onChangeBilling = useCallback((evt) => {
    setBilling(evt.target.value)
  }, [setBilling])

  useEffect(() => {
    if (!localized || equivalentBilling({billing, billingLocalized})) {
      return
    }

    const input = {
      mpm: title.mpm,
      language: lang,
      credit: {
        role: localized.role,
        talent: {id: localized.talent.id},
        ...(!localized.character ? {} : {character: {id: localized.character.id}}),
        billingOrder: (!billing || billing.length===0) ? null : parseInt(billing, 10),
      },
    }
    updateBillingForLocalizedCredit(input).then(result => {
      console.log('TalentAvatar/useEffect/updateBillingForLocalizedCredit, result', result)
    }).catch(error => {
      console.error('TalentAvatar/useEffect/updateBillingForLocalizedCredit, error', error)
    })
  }, [title, lang, billing, billingLocalized, localized, updateBillingForLocalizedCredit])

  return (
<Grid container direction="column" justify="flex-start">
  <Grid container direction="row" justify="center" className={classes.expand}>
    <Billing {...{
      readOnly: (readOnly || !localized),
      onChange: onChangeBilling,
      value: billing || billingOriginal || '',
      highlight: !readOnly && (localized && localized.billingOrder),
      }} />
  </Grid>
  <Grid container direction="row" justify="center" className={classes.expand}>
    <Avatar variant="rounded" classes={{colorDefault}}>
      <MissingAvatar className={classes.icon} />
    </Avatar>
  </Grid>
</Grid>
  )
}

export default TalentAvatar
