import React, { useCallback, useEffect, useState, } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Layout from '../components/Layout'
import { useDebounce } from '../lib/hooks'

import CharacterDetails from '../components/character/CharacterDetails'
import CharacterSearch from '../components/character/CharacterSearch'
import { parseParams } from '../lib/query-params'
import { useHistory } from 'react-router'

const useStylesLayoutItem = makeStyles(theme => ({
  expand: {
    flex: 1,
  },
}))

const LayoutItem = ({children}) => {
  const classes = useStylesLayoutItem()

  return (
<div className={classes.expand}>
  {children}
</div>
  )
}

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

const CharacterEditorForm = ({
  inputValue,
  selected,
  searchResults,
  updateCharacterAkaOriginal,
  updateLocalizationToCharacterAka,
  addAkasToCharacter,
  onClickCloseDetails,
  onClickSelect,
  onClickClearSearch,
  onChangeInputValue,
  onClickAddCharacter,
}) => {
  const classes = useStyles()

  return (
<Grid container direction="row" wrap="nowrap" className={classes.fullHeight}>
  <LayoutItem>
    <CharacterSearch {...{
      inputValue,
      searchResults,
      selected,
      onClickSelect,
      onClickClearSearch,
      onChangeInputValue,
      onClickAddCharacter,
    }} />
  </LayoutItem>
  <LayoutItem>
    <CharacterDetails {...{
      selected,
      onClickCloseDetails,
      updateCharacterAkaOriginal,
      updateLocalizationToCharacterAka,
      addAkasToCharacter,
    }} />
  </LayoutItem>
</Grid>
  )
}

const CharacterEditorPage = (props) => {
  const {
    characters,
    createCharacter,
    searchCharacter,
    updateCharacterAkaOriginal,
    updateLocalizationToCharacterAka,
    addAkasToCharacter,
    location,
    debounceMs=500,
  } = props
  const history = useHistory()
  const queryParams = (location.search && location.search.length > 1) ? parseParams(location.search.slice(1)) : {}
  const characterId = queryParams && queryParams.id
  const [selected, setSelected] = useState(characterId && characters && characters[characterId])
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, debounceMs)
  const [searchResults, setSearchResults] = useState({query: null, fetching: false, results: [], error: null})

  const onClickAddCharacter = useCallback(() => {
    console.log('/character/CharacterSearch/onClickAddTalent', inputValue)
    const input = {
      AKAs: [
        {value: inputValue}
      ]
    }
    createCharacter(input).then(result => {
      console.log('/character/CharacterSearch/onClickAddCharacter, result', result)
      setSelected(result)
    }).catch(error => {
      console.log('/character/CharacterSearch/onClickAddCharacter, error', error)
    })
  }, [inputValue, createCharacter, setSelected])

  const onChangeInputValue = useCallback((e) => {
    setInputValue(e.target.value)
  }, [setInputValue])

  useEffect(() => {
    if (!debouncedInputValue || debouncedInputValue.length === 0) {
      return
    }

    let cancelled = false
    const before = {query: debouncedInputValue, results: [], error: null, fetching: true}
    // TODO: show spinner while searching, setting `before` caused infinite loop
    // setSearchResults(before)
    const input = {
      akaOriginalValue: debouncedInputValue,
      limit: 10,
    }
    searchCharacter(input).then(results => {
      if (cancelled) {
        return
      }
      console.log('/character/CharacterSearch/useEffect/searchCharacter, results', results)
      setSearchResults({...before, results, fetching: false})
    }).catch(error => {
      if (cancelled) {
        return
      }
      console.error('/character/CharacterSearch/useEffect/searchCharacter, error', error)
      setSearchResults({...before, error, fetching: false})
    })

    return () => {
      cancelled = true
    }
  }, [debouncedInputValue, searchCharacter, setSearchResults])

  const onClickClearSearch = useCallback(() => {
    setInputValue('')
    setSearchResults(before => ({...before, results: []}))
  }, [setInputValue, setSearchResults])

  const onClickSelect = useCallback(t => () => {
    setSelected(t)
    history.push(`${location.pathname}?id=${t.id}`)
  }, [setSelected, history, location])

  const onClickCloseDetails = useCallback(() => {
    setSelected(null)
    history.push(location.pathname)
  }, [history, location, setSelected])

  useEffect(() => {
    // exit if no query param
    if (!characterId) {
      setSelected(null)
      return
    }

    // already have it cached?
    if (characters && characters[characterId]) {
      return
    }

    const input = {
      id: [characterId],
    }
    searchCharacter(input).then(results => {
      console.log(`/character/searchCharacter(${JSON.stringify(input)}), results`, results)
      if (results.length > 0) {
        setSelected(results[0])
      }
    }).catch(error => {
      console.log(`/character/searchCharacter(${JSON.stringify(input)}), error`, error)
    })
  }, [searchCharacter, characterId, characters])

  const updateCharacterAkaOriginalWrapper = useCallback(input => {
    return updateCharacterAkaOriginal(input).then(result => {
      if (selected && (input.id === selected.id)) {
        setSelected(result)
      }
      return result
    })
  }, [updateCharacterAkaOriginal, selected, setSelected])

  const updateLocalizationToCharacterAkaWrapper = useCallback(input => {
    return updateLocalizationToCharacterAka(input).then(result => {
      if (selected && (input.id === selected.id)) {
        setSelected(result)
      }
      return result
    })
  }, [updateLocalizationToCharacterAka, selected, setSelected])

  const addAkasToCharacterWrapper = useCallback(input => {
    return addAkasToCharacter(input).then(result => {
      if (selected && (input.id === selected.id)) {
        setSelected(result)
      }
      return result
    })
  }, [addAkasToCharacter, selected, setSelected])

  return (
<Layout { ...{...props} }>
  <CharacterEditorForm { ...{
    inputValue,
    selected,
    searchResults,
    updateCharacterAkaOriginal: updateCharacterAkaOriginalWrapper,
    updateLocalizationToCharacterAka: updateLocalizationToCharacterAkaWrapper,
    addAkasToCharacter: addAkasToCharacterWrapper,
    onClickSelect,
    onClickCloseDetails,
    onClickClearSearch,
    onChangeInputValue,
    onClickAddCharacter,
  }} />
</Layout>
  )
}

export default CharacterEditorPage
