
import {
  Grid,
  makeStyles,
} from '@material-ui/core'

import { sortByKey } from 'deepdown'

import CharacterView from './title-original/CharacterView'
import LocalizationsView from './title-original/LocalizationsView'

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
}))

const OriginalTitleView = ({
  title,
  characters,
  editorLists,
  franchiseCharacters,
  createCharacter,
  addTitlesToCharacterAka,
  removeTitlesFromCharacterAka,
  updateBillingForOriginalCharacterCredit,
  getAtomCharacters,
  importCharactersFromAtom,
  LMTBcp47ToDescription,
}) => {
  const classes = useStyles()
  const originalIndex = (title.localized || []).findIndex(localized => localized.language === title.original.language)
  const original = (originalIndex !== -1) && title.localized[originalIndex]
  const localizations = (originalIndex === -1) ? title.localized : [
    ...title.localized.slice(0, originalIndex),
    ...title.localized.slice(originalIndex + 1),
  ].sort(sortByKey({key: ['language']}))

  return (
<Grid container direction="row" wrap="nowrap" className={classes.fullHeight}>
  <CharacterView {...{importCharactersFromAtom, getAtomCharacters, editorLists, original, characters, title, franchiseCharacters, createCharacter, addTitlesToCharacterAka, updateBillingForOriginalCharacterCredit, removeTitlesFromCharacterAka}} />
  <LocalizationsView {...{mpm: title.mpm, localizations, LMTBcp47ToDescription}} />
</Grid>
  )
}

export default OriginalTitleView
