import React from 'react'

import {
  makeStyles,
  ListItem,
  ListItemText,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  // reference for funky syntax:
  // https://stackoverflow.com/a/63323538/1740008
  selected: {},
  root: {
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}))

const TalentItem = ({
  disabled,
  selected,
  onClickSelect,
  talent,
}) => {
  const classes = useStyles()

  const fullName = talent && talent.AKAs && talent.AKAs[0] && talent.AKAs[0].value

  return (
<ListItem button disabled={disabled} selected={selected} onClick={onClickSelect} classes={{
  root: classes.root,
  selected: classes.selected,
}}>
  <ListItemText primary={fullName || ''} />
</ListItem>
  )
}

export default TalentItem
