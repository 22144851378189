import React, {Fragment} from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { drillDown } from 'deepdown'
// import BrowserDetect from '../../lib/browser'

const useStyles = makeStyles(theme => ({
  IconButton: {
    '& svg': {
      height: 24,
    }
  },
  download: {
    margin: `0 2px`, //${theme.spacing(1)}px`,
    width: 48,
    minWidth: 48,
  },
  buttonDisabled: {
    '& svg': {
      fill: 'grey',
    },
  },
}))

const PreviewExports = ({
  onClickExportImage,
  exportState,
  lang,
}) => {
  const classes = useStyles()

  return (
<Fragment>
  {[
    // for a browser implementation to support TIFF, consider:
    // https://stackoverflow.com/questions/30488116/convert-png-base-64-string-to-tiff-base-64-string
    //
    // for a backend TIFF support, consider:
    // https://coderrocketfuel.com/article/convert-a-svg-image-to-png-jpeg-tiff-webp-and-heif-formats-in-node-js#svg-to-tiff
    {mime: 'tiff', label: 'TIFF', disabled: !lang}, // || ['Chrome', 'Firefox', 'Safari'].includes(BrowserDetect.browser)},
    {mime: 'png', label: 'PNG', disabled: !lang}, // || ['Firefox', 'Safari'].includes(BrowserDetect.browser)},
    // {mime: 'bmp', label: 'BMP', disabled: !lang}, // || ['Chrome', 'Firefox', 'Safari'].includes(BrowserDetect.browser)},
    {mime: 'jpeg', label: 'JPG', disabled: !lang}, // || ['Firefox', 'Safari'].includes(BrowserDetect.browser)},
    // {mime: 'svg', label: 'SVG', disabled: !lang},
  ].map(format => (
    <Button
      key={`/formats[${format.mime}]`}
      disabled={format.disabled}
      size="small"
      onClick={onClickExportImage(format)}
      className={classes.download}
      variant="contained"
      color={exportState[format.mime] ? "secondary" : "primary"}
      >
      {exportState[format.mime] ? (drillDown(exportState, [format.mime, "remaining"]) || "WAIT") : format.label}
    </Button>
  ))}
</Fragment>
  )
}

export default PreviewExports
